<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { PriceServiceFactory } from './prices/PriceServiceFactory';
  import { getCountryZones } from '$lib/api/queries';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let currency = 'EUR';
  export let lead;
  export let supplierTarif;
  export let customerTarif;
  export let baseTarif;
  export let exchangesData;
  export let weight;
  export let diff;
  export let withMdl = true;
  export let showPrice = false;
  let loading = false;
  let error = false;
  let price;
  let discount = 0;
  $: if (lead || supplierTarif || customerTarif || baseTarif || weight) {
    setTimeout(() => {
      calculatePrices();
    }, 10);
  }
  const priceService = PriceServiceFactory.create();

  async function calculatePrices() {
    const request = JSON.parse(JSON.stringify(lead));
    const country = (request.country ?? '').split(' / ')[0];
    const countryWithZones = await getCountryZones(country);
    if (!countryWithZones) {
      return;
    }
    request.weight = weight;
    request.direction = request.direction ?? request.type;
    request.country = countryWithZones;
    loading = true;
    error = false;
    const tariffs = {
      supplierTarif,
      customerTarif,
      baseTarif,
    };

    setTimeout(
      () => {
        console.log('request', JSON.parse(JSON.stringify(request)));

        priceService
          .calculatePrices(request, tariffs, exchangesData, withMdl)
          .then(async result => {
            // console.debug({ price: result.totalEUR, result });

            if (diff > 0) {
              const previousStepPrice = await priceService.calculatePrices(
                { ...request, weight: request.weight - diff, diff: 0 },
                tariffs,
                exchangesData,
                withMdl,
              );
              if (currency === 'MDL') {
                price = result.totalMDL - previousStepPrice.totalMDL;
              } else {
                price = result.totalEUR - previousStepPrice.totalEUR;
              }
              discount =
                result.discountTotalEur - previousStepPrice.discountTotalEur;
            } else {
              if (currency === 'MDL') {
                price = result.totalMDL;
              } else {
                price = result.totalEUR;
              }
              discount = result.discountTotalEur;
              if (
                Math.abs(lead.totalEUR - result.totalEUR) > 0.1 ||
                Math.abs(lead.totalNational - result.totalMDL) > 1
              ) {
                lead.totalEUR = result.totalEUR;
                lead.exchangeRate = result.exchangeRate;
                lead.totalNational = result.totalMDL;
                lead.rate = result;
                dispatch('rateChange', { rate: result });
              }
            }
            console.log('remote result', result);
          })
          .catch(e => {
            console.error(e);
            error = true;
          })
          .finally(() => {
            loading = false;
          });
      },
      showPrice ? Math.random() * 1 : 0,
    );
  }
</script>

{#if showPrice}
  <div
    class="w-full dark:text-white"
    class:bg-muted={discount > 0}
    class:font-bold={diff > 0}
  >
    {#if loading}
      <span class="flex relative mx-auto w-3 h-3">
        <span
          class="inline-flex absolute w-full h-full bg-sky-400 rounded-full opacity-75 animate-ping dark:bg-sky-600"
        ></span>
        <span
          class="inline-flex relative w-3 h-3 bg-sky-500 rounded-full dark:bg-sky-400"
        ></span>
      </span>
    {:else}
      <span
        class:text-red-500={error}
        class:dark:text-red-400={error}
        class="block p-2 hover:bg-gray-600 hover:text-gray-100"
      >
        {error
          ? 'ERROR'
          : (price?.toFixed(currency === 'MDL' ? 0 : 2) ?? '0.00')}
      </span>
    {/if}
  </div>
{/if}
<Button class="hidden" on:click={calculatePrices}>Calculate Prices</Button>
