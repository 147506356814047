<script lang="ts">
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';

  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { popupStore } from '$lib/stores/app-store';
  import type { Pipeline } from '$lib/types/pipeline';
  import { updateSourceField } from '$lib/api/mutations';
  import { removePipelineItem } from '$lib/api/pipeline-api';

  export let item: any;
  export let pipeline: Pipeline;

  function openInfo() {
    popupStore.openPopup('crmObject', {
      module: pipeline?.module,
      id: item[pipeline.module.slice(0, -1) + 'Id'],
    });
  }

  function openKanbanInfo() {
    popupStore.openPopup('crmObject', {
      module: 'pipelineItems',
      id: item.id,
    });
  }

  function copyLink() {
    // Implement copy link functionality
    const link = `${window.location.origin}/pipelines/${pipeline.id}/items/${item.id}`;
    navigator.clipboard.writeText(link);
  }

  function archiveItem() {
    // Implement archive functionality
    console.log('Archive item:', item.id);
  }

  async function onDeleteItem() {
    await removePipelineItem(item.id);
  }

  async function handleModifySource() {
    if (!pipeline.modifySource || !pipeline.sourceColumn) {
      return;
    }

    const sourceItem = item[pipeline.module];
    if (!sourceItem) {
      return;
    }

    try {
      await updateSourceField(
        pipeline.module,
        sourceItem.id,
        pipeline.sourceColumn,
        item.phase.name,
      );
    } catch (error) {
      console.error('Failed to update source field:', error);
    }
  }
  let open = false;
</script>

<div class="">
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="ghost"
        size="icon"
        class="h-8 w-8 hover:bg-muted/50"
      >
        <Icon icon="mdi:dots-horizontal" class="h-5 w-5" />
        <span class="sr-only">Open menu</span>
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content
      class="w-72 shadow-lg rounded-lg border-none bg-popover font-inter"
    >
      <DropdownMenu.Label class="text-sm font-semibold"
        >Actions</DropdownMenu.Label
      >
      <DropdownMenu.Separator />
      <DropdownMenu.Item on:click={openKanbanInfo}>
        <Icon icon="solar:text-field-bold" class="mr-2 h-4 w-4" />
        <span>Open pipeline item</span>
      </DropdownMenu.Item>
      <DropdownMenu.Item on:click={openInfo}>
        <Icon icon="mdi:open-in-new" class="mr-2 h-4 w-4" />
        <span>Open {item?.name ?? ''} in {pipeline.module}</span>
      </DropdownMenu.Item>

      <DropdownMenu.Item on:click={copyLink} class="hidden">
        <Icon icon="mdi:link" class="mr-2 h-4 w-4" />
        <span>Copy link</span>
      </DropdownMenu.Item>
      {#if pipeline.modifySource && pipeline.sourceColumn}
        <DropdownMenu.Separator />
        <DropdownMenu.Item on:click={handleModifySource}>
          <Icon icon="mdi:source-branch" class="mr-2 h-4 w-4" />
          <span>Modify Source</span>
        </DropdownMenu.Item>
      {/if}
      <DropdownMenu.Separator />
      <DropdownMenu.Item on:click={archiveItem} class="hidden">
        <Icon icon="mdi:archive" class="mr-2 h-4 w-4" />
        <span>Archive</span>
      </DropdownMenu.Item>
      <DropdownMenu.Item class="text-destructive" on:click={onDeleteItem}>
        <Icon icon="mdi:delete" class="mr-2 h-4 w-4" />
        <span>Delete</span>
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
</div>

<style>
  :global(.dropdown-menu-content) {
    z-index: 50;
  }
</style>
