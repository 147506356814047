<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { getAccountLeads } from '$lib/api/queries';
  import { appStore } from '$lib/stores/app-store';
  import { newItemStore } from '$lib/stores/new-item-store';
  import Icon from '@iconify/svelte';

  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import { format } from 'date-fns';
  import { formatCurrency } from '$lib/utils/hanta-utils';

  import { popupStore } from '$lib/stores/app-store';

  export let accountId: string;

  const leadsQuery = createQuery({
    queryKey: ['accountLeads', accountId],
    queryFn: () => getAccountLeads(accountId),
    enabled: !!accountId,
  });

  $: ({ data: leads, isLoading, isError, error } = $leadsQuery);

  function getStatusColor(status: string) {
    switch (status.toUpperCase()) {
      case 'NEW':
        return 'bg-blue-100 text-blue-800';
      case 'QUALIFIED':
        return 'bg-green-100 text-green-800';
      case 'PROPOSAL':
        return 'bg-yellow-100 text-yellow-800';
      case 'NEGOTIATION':
        return 'bg-purple-100 text-purple-800';
      case 'CLOSED WON':
        return 'bg-green-500 text-white';
      case 'CLOSED LOST':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  }

  function createNewItem() {
    if ($appStore.currentModule) {
      newItemStore.createItem('leads');
    }
    newItemStore.openDialog();
  }
</script>

<Button
  on:click={createNewItem}
  variant="outline"
  data-testid="nav-new-item"
  class="my-2"
>
  <Icon icon="mdi:plus" class="size-4 text-primary" aria-hidden="true" />
  <span class="ml-2">Create Lead</span>
  <span class="ml-auto text-xs text-muted-foreground hidden">⌘K</span>
</Button>

{#if isLoading}
  <p>Loading leads...</p>
{:else if isError}
  <p>Error loading leads: {error.message}</p>
{:else if leads && leads.length > 0}
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Actions</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Name</TableHead>
        <TableHead>Product</TableHead>
        <TableHead>Country</TableHead>
        <TableHead>Weight</TableHead>
        <TableHead>Total</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {#each leads as lead}
        <TableRow>
          <TableCell>
            <Button
              variant="outline"
              size="sm"
              on:click={() => {
                popupStore.openPopup('crmObject', {
                  id: lead.id,
                  module: 'leads',
                });
              }}>View</Button
            >
          </TableCell>
          <TableCell>
            <span
              class={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(lead.status)}`}
            >
              {lead.status}
            </span>
          </TableCell>
          <TableCell>{lead.name}</TableCell>

          <TableCell>{lead.shipment?.product}</TableCell>
          <TableCell>{lead.shipment?.country}</TableCell>
          <TableCell>{lead.shipment?.weight} kg</TableCell>
          <TableCell>
            {lead.shipment?.rate?.totalEUR
              ? formatCurrency(lead.shipment?.rate?.totalEUR)
              : '-'}</TableCell
          >
        </TableRow>
      {/each}
    </TableBody>
  </Table>
{:else}
  <p>No leads found for this account.</p>
{/if}
