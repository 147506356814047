// lib/queryStore.ts
import {
  createQuery,
  createInfiniteQuery,
  createMutation,
  useQueryClient,
} from '@tanstack/svelte-query';
import { supabase } from '../supabase';
import type { Filter, QueryConfig } from '.';
import { get } from 'svelte/store';
import { authStore } from '$lib/stores/auth-store';

export function createSupabaseQuery<T extends { id: number }>({
  table,
  select = '*',
  filters = [],
  sort,
  pagination,
  queryKey,
  infiniteScroll = false,
  queryOptions = {},
}: QueryConfig<T>) {
  const queryClient = useQueryClient();

  // Функция построения запроса
  function buildQuery(pageParam?: number) {
    let query = supabase(get(authStore)?.token)
      .from(table)
      .select(select, { count: 'exact' });

    // Применяем фильтры
    filters.forEach(filter => {
      const { field, operator, value } = filter;
      switch (operator) {
        case 'eq':
          query = query.eq(field, value);
          break;
        case 'neq':
          query = query.neq(field, value);
          break;
        // ... остальные операторы
      }
    });

    if (sort) {
      query = query.order(sort.field, {
        ascending: sort.ascending ?? true,
      });
    }

    if (infiniteScroll) {
      const start = (pageParam || 0) * (pagination?.pageSize || 10);
      query = query.range(start, start + (pagination?.pageSize || 10) - 1);
    }

    return query;
  }

  // Основной запрос
  const query = infiniteScroll
    ? createInfiniteQuery({
        queryKey,
        queryFn: ({ pageParam = 0 }) => buildQuery(pageParam),
        getNextPageParam: (lastPage: any) => lastPage.nextPage,
        ...queryOptions,
      })
    : createQuery({
        queryKey,
        queryFn: () => buildQuery(),
        ...queryOptions,
      });

  // Подписка на реалтайм
  const subscription = supabase(get(authStore)?.token)
    .channel(`changes_${table}`)
    .on('postgres_changes', { event: '*', schema: 'public', table }, () =>
      queryClient.invalidateQueries({ queryKey }),
    )
    .subscribe();

  function destroy() {
    subscription.unsubscribe();
  }

  return {
    query,
    destroy,
  };
}
