<script lang="ts">
  import DealsCard from './deals-card.svelte';
  import TimelineCard from './timeline-card.svelte';
  import Prices from '../prices/prices.svelte';

  import * as Tabs from '$lib/components/ui/tabs';
  import { appStore } from '$lib/stores/app-store';
  import { authStore } from '$lib/stores/auth-store';
  import Activities from '../activities/activities.svelte';

  let currentTab = 'timeline';
</script>

{#if $appStore.isMobile}
  <Tabs.Root bind:value={currentTab} class="">
    <Tabs.List class="grid-cols-3 ml-1 max-w-xs tn-grid">
      <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>
      <Tabs.Trigger value="deals">Deals</Tabs.Trigger>
    </Tabs.List>
    <Tabs.Content value="timeline">
      {#if currentTab === 'timeline'}
        <TimelineCard class="w-full" />
      {/if}
    </Tabs.Content>
    <Tabs.Content value="deals">
      {#if currentTab === 'deals'}
        <DealsCard class="w-full" />
      {/if}
    </Tabs.Content>
  </Tabs.Root>
{:else}
  <div
    class="grid-cols-1 gap-4 overflow-scroll tn-grid md:grid-cols-2 xl:grid-cols-3 h-[calc(100vh-7rem)] pt-12"
  >
    {#if authStore.isLogistics()}
      <div class="col-span-2 pr-6 w-full text-lg p-1">
        <Prices
          withMdl={true}
          withCalculator={true}
          currency="MDL"
          refDate={new Date()}
        />
      </div>
    {:else if authStore.isHeadhunter()}
      <DealsCard class="w-full min-h-96" />
    {/if}
    <div class="mt-4 col-span-1 overflow-scroll pt-4 px-4">
      <Activities
        module="dashboard"
        search={''}
        type=""
        withSearch={false}
        withTabs={true}
        hideLabels={true}
      />
    </div>
    <!--TimelineCard class="col-span-2 w-full xl:col-span-1" /-->
  </div>
{/if}
