<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';

  export let onPhaseAdd: () => void;
  export let onSave: () => void;
</script>

<div class="flex justify-between items-center m-2">
  <div class="space-x-2">
    <Button variant="outline" on:click={onPhaseAdd}>
      <Icon icon="mdi:plus" class="mr-2 w-4 h-4" />
      Add Phase
    </Button>
    <Button variant="default" on:click={onSave}>
      <Icon icon="mdi:content-save" class="mr-2 w-4 h-4" />
      Save Pipeline
    </Button>
  </div>
</div>
