<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { appStore } from '$lib/stores/app-store';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import { Button } from '$lib/components/ui/button';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import HantaInputTextLean from '$lib/widgets/input/hanta-input-text-lean.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';

  export let module: string;

  const dispatch = createEventDispatcher();

  let item = {};

  $: {
    item = getInitialItemState(module);
  }

  function getInitialItemState(module: string) {
    switch (module) {
      case 'accounts':
      case 'deals':
        return { name: '' };
      case 'invoices':
      case 'contracts':
      case 'leads':
      case 'issues':
      case 'orders':
        return {
          customer:
            $appStore.selected?.module === 'accounts' && $appStore.selected?.id
              ? {
                  id: $appStore.selected?.id,
                }
              : null,
        };
      case 'candidates':
      case 'contacts':
        return { firstname: '', lastname: '' };
      default:
        return {};
    }
  }

  function handleSubmit() {
    let newItem = { ...item };

    if (module === 'candidates' || module === 'contacts') {
      newItem.name = `${item.firstname} ${item.lastname}`;
      newItem.role = module === 'candidates' ? 'Candidate' : 'Contact';
    } else if (module === 'contracts') {
      newItem = {
        ...newItem,
        name: 'New Contract',
        status: 'In development',
        salesId: $currentUserStore?.id,
        accountId: newItem.customer?.id,
        customFields: {
          zonesTnt: ['0', '1', '2', '3', '4', '5', '6'],
          zonesFedex: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
          ],
        },
      };
      delete newItem.customer;
    } else if (module === 'tariffs') {
      newItem = {
        ...newItem,
        startDate: new Date(),
        type: 'Customer',
        currency: 'EUR',
        name: item.name,
        pricelists: [],
      };
    } else if (module === 'invoices') {
      newItem = {
        ...newItem,
        name: 'New Invoice',
        customer: item.customer?.id,
        status: 'Draft',
        positions: [],
        tags: [],
      };
    } else if (module === 'leads') {
      newItem = {
        ...newItem,
        country: item.country,
        name: 'New Lead',
        customer: item.customer?.id,
        expectedCloseDate: new Date(),
        assignedTo: $currentUserStore?.id,
      };
    } else if (module === 'orders') {
      newItem = {
        ...newItem,
        country: item.country,
        name: item.name,
        customer: item.customer?.id,
        orderDate: new Date(),
        assignedTo: $currentUserStore?.id,
      };
    } else if (module === 'issues') {
      newItem = {
        ...newItem,
        name: 'New Issue',
        status: 'New',
        issuedOn: new Date(),
        reporterId: $currentUserStore?.id,
      };
    }

    dispatch('submit', newItem);
  }
</script>

<div class="p-6">
  <h1 class="mb-4 text-xl capitalize" data-testid={`${module}-table`}>
    {module}
  </h1>
  <form class="space-y-8" on:submit|preventDefault={handleSubmit}>
    {#if module === 'accounts' || module === 'deals' || module === 'tariffs'}
      <div>
        <Label for="name">Name</Label>
        <Input
          id="name"
          bind:value={item.name}
          class="w-full mt-2"
          data-testid="title-input"
          autofocus
          required
        />
      </div>
    {:else if ['invoices', 'contracts', 'leads', 'issues', 'orders'].includes(module)}
      <div class="h-[300px] flex flex-col space-y-8">
        <AccountSelector
          bind:value={item.customer}
          initialValue={item.customer?.id}
        />

        {#if module === 'orders' || module === 'leads'}
          <HantaInputLovSelect
            bind:value={item.country}
            label="Country"
            type="Countries"
            multiple={false}
          />
        {/if}
        {#if module === 'orders'}
          <div>
            <HantaInputTextLean
              bind:value={item.name}
              label="AWB Number"
              data-testid="title-input"
            />
          </div>
        {/if}
      </div>
    {:else if module === 'candidates' || module === 'contacts'}
      <div>
        <Label for="firstName">First name</Label>
        <Input
          id="firstName"
          bind:value={item.firstname}
          class="w-full"
          data-testid="title-input"
          autofocus
        />
      </div>
      <div>
        <Label for="lastName">Last name</Label>
        <Input
          id="lastName"
          bind:value={item.lastname}
          class="w-full"
          data-testid="description-input"
        />
      </div>
    {/if}

    <div class="flex gap-4">
      <Button
        type="button"
        variant="secondary"
        class="w-full"
        data-testid="cancel-button"
        on:keydown={event => {
          if (event.key === 'Enter') {
            dispatch('cancel');
          }
        }}
        on:click={() => dispatch('cancel')}>Cancel</Button
      >
      <Button
        variant="default"
        class="w-full"
        data-testid="save-button"
        on:keydown={event => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
        on:click={handleSubmit}>Create</Button
      >
    </div>
  </form>
</div>
