<script context="module" lang="ts">
  function getUniqueValues(pipeline: Pipeline, field: string): any[] {
    const values = new Set();

    pipeline.phases?.forEach(phase => {
      phase.items?.forEach(item => {
        if (item.customFields?.[field] !== undefined) {
          values.add(item.customFields[field]);
        }
      });
    });

    return Array.from(values).sort();
  }
</script>

<script lang="ts">
  import { Input } from '$lib/components/ui/input';
  import { Button } from '$lib/components/ui/button';
  import { Search, Filter, ArrowUpDown } from 'lucide-svelte';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import { cn } from '$lib/utils';
  import type { Pipeline, PipelineItem } from '$lib/types/pipeline';
  import { createEventDispatcher } from 'svelte';

  export let pipeline: Pipeline;
  export let searchQuery = '';
  export let sortField: string | null = null;
  export let sortDirection: 'asc' | 'desc' = 'asc';
  export let activeFilters: Record<string, any> = {};

  const dispatch = createEventDispatcher();

  // Get all unique fields from pipeline items
  $: allFields = getAllFields(pipeline);

  function getAllFields(pipeline: Pipeline): string[] {
    const fields = new Set<string>();

    pipeline.phases?.forEach(phase => {
      phase.items?.forEach(item => {
        if (item.customFields && typeof item.customFields === 'object') {
          Object.keys(item.customFields).forEach(key => fields.add(key));
        }
      });
    });

    return Array.from(fields).sort();
  }

  function handleSearch(e: Event) {
    const query = (e.target as HTMLInputElement).value;
    dispatch('search', { query });
  }

  function handleSort(field: string) {
    if (sortField === field) {
      // Toggle direction if same field
      dispatch('sort', {
        field,
        direction: sortDirection === 'asc' ? 'desc' : 'asc',
      });
    } else {
      // New field, default to asc
      dispatch('sort', { field, direction: 'asc' });
    }
  }

  function handleFilter(field: string, value: any) {
    dispatch('filter', { field, value });
  }

  function clearFilter(field: string) {
    dispatch('clearFilter', { field });
  }

  function clearAllFilters() {
    dispatch('clearAllFilters');
  }
</script>

<div class="flex items-center gap-2 p-2 border-b">
  <!-- Search -->
  <div class="relative">
    <Search class="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
    <Input
      placeholder="Search in pipeline..."
      class="pl-8 w-xl"
      value={searchQuery}
      on:input={handleSearch}
    />
  </div>

  <!-- Sort -->
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        variant="outline"
        class={cn('gap-2', sortField && 'bg-secondary')}
        builders={[builder]}
      >
        <ArrowUpDown class="h-4 w-4" />
        {#if sortField}
          Sort: {sortField} ({sortDirection})
        {:else}
          Sort
        {/if}
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      {#each allFields as field}
        <DropdownMenu.Item on:click={() => handleSort(field)}>
          {field}
          {#if sortField === field}
            ({sortDirection})
          {/if}
        </DropdownMenu.Item>
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  <!-- Filter -->
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        variant="outline"
        class={cn(
          'gap-2',
          Object.keys(activeFilters).length > 0 && 'bg-secondary',
        )}
        builders={[builder]}
      >
        <Filter class="h-4 w-4" />
        {#if Object.keys(activeFilters).length > 0}
          Filters: {Object.keys(activeFilters).length}
        {:else}
          Filter
        {/if}
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      {#if Object.keys(activeFilters).length > 0}
        <DropdownMenu.Item on:click={clearAllFilters}>
          Clear all filters
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
      {/if}
      {#each allFields as field}
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>
            {field}
            {#if activeFilters[field]}
              (active)
            {/if}
          </DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            {#if activeFilters[field]}
              <DropdownMenu.Item on:click={() => clearFilter(field)}>
                Clear filter
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
            {/if}
            <!-- Get unique values for this field -->
            {#each getUniqueValues(pipeline, field) as value}
              <DropdownMenu.Item on:click={() => handleFilter(field, value)}>
                {value}
              </DropdownMenu.Item>
            {/each}
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Root>
</div>
