<script lang="ts">
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import * as Sheet from '$lib/components/ui/sheet/index.js';
  import { Button } from '$lib/components/ui/button/index.js';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import * as Form from '$lib/components/ui/form';
  import { appStore } from '$lib/stores/app-store';

  import { type Timelog, insertTimelogSchema } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import HantaInputTime from '$lib/widgets/input/hanta-input-time.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import { deleteItem, saveTimelog } from '$lib/api/mutations';
  import { toast } from 'svelte-sonner';

  export let timeEntry;
  export let open = false;
  export let nextDay;

  const onDeleteItem = async () => {
    if (!timeEntry.id) {
      return;
    }

    await deleteItem('timelogs', timeEntry.id);
    invalidateCache();

    toast(`Item deleted successfully`, {
      duration: UNDO_TIMEOUT,
      action: {
        label: 'Undo',
        onClick: async () => {
          await saveTimelog(timeEntry);
          invalidateCache();
        },
      },
    });
  };

  const UNDO_TIMEOUT = 5000;

  const invalidateCache = () => {
    $appStore.queryClient.invalidateQueries(['timelogs'], {
      exact: true,
      refetchActive: true,
    });
  };

  const client = useQueryClient();
  const addMutation = createMutation({
    mutationFn: (variables): Promise<Timelog> =>
      Promise.resolve(saveTimelog(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['timelogs'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['timelogs'] });
      const previousItems = client.getQueryData<Timelog[]>(['timelogs']);

      client.setQueryData(['timelogs'], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['timelogs'], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['timelogs'] });
    },
  });

  const formSchema = insertTimelogSchema.partial();
  let initTimeEntry = {
    ...timeEntry,
  };
  const form = superForm<Timelog>(initTimeEntry, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(formSchema),
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);

      $addMutation.mutate($formData, {
        onError(error) {
          console.error('Error', error);
        },
        onSuccess: timelog => {
          // onUpdate(timelog);
          open = false;
        },
      });
    },
  });

  let { form: formData, enhance, errors } = form;

  $: $formData = timeEntry;

  $: startDatetime = new Date('2024-01-01' + 'T' + $formData.starttime);
  $: endDatetime = new Date('2024-01-01' + 'T' + $formData.endtime);
  $: durationInMin =
    (endDatetime - startDatetime) / 1000 / 60 - $formData.pause;
  $: durationHours = Math.floor(durationInMin / 60)
    .toString()
    .padStart(2, '0');
  $: durationMins = (durationInMin % 60).toString().padStart(2, '0');

  const onDuplicate = () => {
    form.submit();

    setTimeout(() => {
      timeEntry = {
        ...timeEntry,
        id: undefined,
        date: nextDay.toISOString(),
      };
      open = true;
    }, 300);
  };
</script>

<Sheet.Root bind:open>
  <Sheet.Content side="right" data-testid="timelog-dialog">
    <form
      autocomplete="off"
      class="flex flex-col gap-0 w-full h-full"
      datatype="JSON"
      method="POST"
      use:enhance
    >
      <Sheet.Header>
        <Sheet.Title class="text-left"
          >Time entry
          <span class="sr-only" data-testid="timelog-title"
            >for {new Date(timeEntry.date).toLocaleDateString('de', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}</span
          >

          <Button variant="ghost" on:click={onDuplicate}>Duplicate</Button>
        </Sheet.Title>
      </Sheet.Header>

      <div class="my-12">
        <HantaInputDate
          {form}
          label="Date"
          name="date"
          required={true}
          data-testid="timelog-date"
        >
          <div slot="display" data-testid="date-display" let:value>
            {#if value}
              {value.day.toString().padStart(2, '0')}.{value.month
                .toString()
                .padStart(2, '0')}
            {/if}
          </div>
        </HantaInputDate>
        <div class="flex items-baseline gap-3">
          <HantaInputTime
            {form}
            label="Start time"
            name="starttime"
            required={true}
            data-testid="timelog-start-time"
          />
          <HantaInputTime
            {form}
            label="End time"
            name="endtime"
            required={true}
            data-testid="timelog-end-time"
          />
          <HantaInputNumber
            {form}
            label="Pause in minutes"
            name="pause"
            required={true}
            step={1}
            asFloat={false}
            data-testid="timelog-pause"
          />
        </div>

        {#if $errors.pause}
          <div class="text-red-500 text-sm mt-1" data-testid="timelog-error">
            {$errors.pause}
          </div>
        {/if}
        <div
          class="px-4 py-2 my-4 w-24 font-mono text-xl font-bold border border-solid"
          data-testid="timelog-duration"
        >
          {durationHours === 'NaN' ? '00' : durationHours}:{durationMins ===
          'NaN'
            ? '00'
            : durationMins}
        </div>
        <div class="flex flex-col gap-4">
          <HantaInputLovSelect
            {form}
            bind:value={$formData.project}
            name="project"
            type="Project"
            label="Project"
            placeholder="Select a project"
            multiple={false}
            addable={true}
            data-testid="timelog-project"
          />
          <HantaInputTextarea
            {form}
            name="description"
            label="Description"
            data-testid="timelog-description"
          />
        </div>
      </div>

      <Sheet.Footer>
        <Sheet.Close asChild let:builder>
          <div
            class={`flex ${$formData.id ? 'justify-between' : 'justify-end'}  w-full`}
          >
            {#if $formData.id}
              <Button
                variant="ghost"
                class="text-red-500"
                builders={[builder]}
                on:click={onDeleteItem}
              >
                Delete
              </Button>
            {/if}
            <div class="flex gap-2">
              <Button
                variant="secondary"
                builders={[builder]}
                data-testid="timelog-dialog-close">Cancel</Button
              >
              <Form.Button
                type="submit"
                disabled={!$formData.date || !durationInMin}
                variant="default"
                data-testid="timelog-dialog-save">Save</Form.Button
              >
            </div>
          </div>
        </Sheet.Close>
      </Sheet.Footer>
    </form>
  </Sheet.Content>
</Sheet.Root>
