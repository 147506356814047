<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import { Textarea } from '$lib/components/ui/textarea';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { formatDistanceToNow } from 'date-fns';

  export let avatar: string = '';
  export let username: string = '';
  export let content: string = '';
  export let createdAt: string = '';

  export let currentUserId: string | undefined = undefined;
  export let onDelete: () => void;
  export let onEdit: (content: string) => void;

  let isEditing = false;
  let editedContent = content;

  function handleEdit() {
    if (editedContent.trim() === content) {
      isEditing = false;
      return;
    }
    onEdit(editedContent);
    isEditing = false;
  }

  function cancelEdit() {
    editedContent = content;
    isEditing = false;
  }
</script>

<div class="flex gap-3 group">
  <Avatar.Root class="h-8 w-8">
    <Avatar.Image src={avatar} alt={username} />
    <Avatar.Fallback>
      {username ? (username.split(' ')[0].substring(0, 1) + (username.split(' ')[1]?.substring(0, 1) || '')) : '-'}
    </Avatar.Fallback>
  </Avatar.Root>

  <div class="flex-1">
    <div class="flex items-center gap-2">
      <span class="font-medium">{username}</span>
      <span class="text-sm text-muted-foreground">
        {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
      </span>

      {#if currentUserId === username}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild let:builder>
            <Button
              variant="ghost"
              size="icon"
              class="opacity-0 group-hover:opacity-100 transition-opacity ml-auto h-6 w-6"
              builders={[builder]}
            >
              <Icon icon="mdi:dots-vertical" class="h-4 w-4" />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            <DropdownMenu.Item on:click={() => (isEditing = true)}>
              <Icon icon="mdi:pencil" class="mr-2 h-4 w-4" />
              Edit
            </DropdownMenu.Item>
            <DropdownMenu.Item class="text-destructive" on:click={onDelete}>
              <Icon icon="mdi:delete" class="mr-2 h-4 w-4" />
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      {/if}
    </div>

    {#if isEditing}
      <div class="mt-2 space-y-2">
        <Textarea bind:value={editedContent} class="min-h-[100px]" autofocus />
        <div class="flex justify-end gap-2">
          <Button variant="ghost" size="sm" on:click={cancelEdit}>
            Cancel
          </Button>
          <Button
            size="sm"
            on:click={handleEdit}
            disabled={!editedContent.trim() || editedContent === content}
          >
            Save
          </Button>
        </div>
      </div>
    {:else}
      <p class="mt-1 text-sm whitespace-pre-wrap">{content}</p>
    {/if}
  </div>
</div>
