<script lang="ts">
  import { cn } from '$lib/utils/hanta-utils';
  import PdfIcon from '$lib/components/icons/pdf-icon.svelte';
  import WordIcon from '$lib/components/icons/word-icon.svelte';

  import { toast } from 'svelte-sonner';
  import Icon from '@iconify/svelte';
  import { deleteFile, downloadFile, getFileUrl } from '$lib/api/storage';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import FilePreview from './file-preview.svelte';

  export let file;
  export let folder;
  export let refresh = 0;
  export let compact = false;
  export let withPreviewImages = false;
  let showPreview = false;
  let previewUrl;
  let blob;

  const onCreatePublicLink = async (expiresIn: number) => {
    const { signedUrl } = await getFileUrl(folder, file?.name, expiresIn);
    try {
      await navigator.clipboard.writeText(signedUrl);
      toast.success('URL copied to clipboard', { duration: 3000 });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const onPreview = async () => {
    createPreviewUrl();
    showPreview = true;
  };

  const createPreviewUrl = async () => {
    if (!previewUrl) {
      if (isOfficeDocument) {
        const { signedUrl } = await getFileUrl(folder, file?.name);
        previewUrl = signedUrl;
      } else {
        if (import.meta.env.MODE === 'plugin') {
          const { signedUrl } = await getFileUrl(folder, file?.name);
          previewUrl = signedUrl;
        } else {
          const blob = await downloadFile(folder, file?.name);
          previewUrl = URL.createObjectURL(blob);
        }
      }
    }
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    link.remove();
  };

  const onDownload = async () => {
    if (!blob) {
      blob = await downloadFile(folder, file?.name);
    }
    downloadBlob(blob, file?.name);
  };

  const onDelete = async () => {
    await deleteFile(folder, file?.name);
    refresh = refresh + 1;
  };

  $: isDocument = file?.name?.toLowerCase().endsWith('.docx');
  $: isPdf = file?.name?.toLowerCase().endsWith('.pdf');
  $: isImage = !!['.png', '.jpg', '.jpeg', '.gif'].find(el =>
    file?.name?.toLowerCase().endsWith(el),
  );

  $: if (withPreviewImages && file) {
    if (isImage) {
      createPreviewUrl();
    }
  }

  $: isOfficeDocument =
    file?.name?.endsWith('.docx') ||
    file?.name?.endsWith('.doc') ||
    file?.name?.endsWith('.pptx') ||
    file?.name?.endsWith('.ppt') ||
    file?.name?.endsWith('.xlsx') ||
    file?.name?.endsWith('.xls');
</script>

{#if compact}
  <!-- Compact View -->
  <Button
    variant="ghost"
    size="sm"
    class="flex gap-2 items-center px-2 py-4 w-full h-8"
    on:click={() => onPreview && onPreview()}
  >
    <div class="text-primary">
      {#if isDocument}
        <WordIcon class="size-6" />
      {:else if isPdf}
        <PdfIcon class="size-6" />
      {:else if isImage}
        <Icon icon="mdi:image" class="text-accent size-4 " />
      {:else}
        <Icon icon="mdi:file-outline" class="size-4 text-primary" />
      {/if}
    </div>
    <span class="text-sm truncate">{file?.name}</span>
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant="ghost"
          size="icon"
          on:click={e => e.stopPropagation()}
          class="ml-auto w-6 h-6 hover:bg-primary/10"
        >
          <Icon icon="mdi:chevron-down" class="size-3" />
        </Button>
      </DropdownMenu.Trigger>
      <!-- Same dropdown content as before -->
      <DropdownMenu.Content class="w-64">
        <DropdownMenu.Label class="truncate">{file?.name}</DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Item on:click={onPreview}>Show</DropdownMenu.Item>
        <DropdownMenu.Item on:click={onDownload}>Download</DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>Get URL</DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent class="w-48">
            <DropdownMenu.Item on:click={() => onCreatePublicLink(60)}>
              Expire in 1 minute
            </DropdownMenu.Item>
            <DropdownMenu.Item on:click={() => onCreatePublicLink(60 * 60)}>
              Expire in 1 hour
            </DropdownMenu.Item>
            <DropdownMenu.Item
              on:click={() => onCreatePublicLink(60 * 60 * 24)}
            >
              Expire in 1 day
            </DropdownMenu.Item>
            <DropdownMenu.Item
              on:click={() => onCreatePublicLink(60 * 60 * 24 * 7)}
            >
              Expire in 1 week
            </DropdownMenu.Item>
            <DropdownMenu.Item
              on:click={() => onCreatePublicLink(60 * 60 * 24 * 30)}
            >
              Expire in 1 month
            </DropdownMenu.Item>
            <DropdownMenu.Item
              on:click={() => onCreatePublicLink(60 * 60 * 24 * 365)}
            >
              Expire in 1 year
            </DropdownMenu.Item>
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
        <DropdownMenu.Separator />
        <DropdownMenu.Item class="text-red-500" on:click={onDelete}>
          Delete
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  </Button>
{:else}
  <!-- Original View -->
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="outline"
        class="relative w-full h-full shadow-xs bg-primary-800 hover:bg-primary/10"
        on:click={e => e.stopPropagation()}
      >
        <div class="flex overflow-hidden flex-1 items-center p-1 mr-7">
          <div class="mr-1 text-primary">
            {#if isDocument}
              <WordIcon />
            {:else if isPdf}
              <PdfIcon />
            {:else if isImage}
              {#if withPreviewImages}
                <img
                  src={previewUrl}
                  alt={file?.name}
                  class="scale-object-fit block"
                />
              {:else}
                <Icon icon="mdi:image" class="text-accent size-8" />
              {/if}
            {:else}
              <Icon icon="mdi:file-outline" class="size-8 text-primary" />
            {/if}
          </div>
          <div class="ml-1 text-left truncate">
            <p class="text-sm font-medium truncate text-primary">
              {file?.name}
            </p>
            <p class="text-xs text-primary/50">
              {Math.round(file?.metadata?.size / 1024)} KB
            </p>
          </div>
        </div>

        <Icon
          icon="mdi:chevron-down"
          class={cn(
            'absolute right-2 size-4',
            builder['aria-expanded'] && 'translate rotate-180',
          )}
        />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="w-64">
      <DropdownMenu.Label class="truncate">{file?.name}</DropdownMenu.Label>
      <DropdownMenu.Separator />
      <DropdownMenu.Item on:click={onPreview}>Show</DropdownMenu.Item>
      <DropdownMenu.Item on:click={onDownload}>Download</DropdownMenu.Item>
      <DropdownMenu.Separator />

      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger>Get URL</DropdownMenu.SubTrigger>
        <DropdownMenu.SubContent class="w-48">
          <DropdownMenu.Item on:click={() => onCreatePublicLink(60)}
            >Expire in 1 minute</DropdownMenu.Item
          >
          <DropdownMenu.Item on:click={() => onCreatePublicLink(60 * 60)}
            >Expire in 1 hour</DropdownMenu.Item
          >
          <DropdownMenu.Item on:click={() => onCreatePublicLink(60 * 60 * 24)}
            >Expire in 1 day</DropdownMenu.Item
          >
          <DropdownMenu.Item
            on:click={() => onCreatePublicLink(60 * 60 * 24 * 7)}
            >Expire in 1 week</DropdownMenu.Item
          >
          <DropdownMenu.Item
            on:click={() => onCreatePublicLink(60 * 60 * 24 * 30)}
            >Expire in 1 month</DropdownMenu.Item
          >
          <DropdownMenu.Item
            on:click={() => onCreatePublicLink(60 * 60 * 24 * 365)}
            >Expire in 1 year</DropdownMenu.Item
          >
          <!--Todo:  not in v1 
          <DropdownMenu.Separator />
          <DropdownMenu.Item>Custom expire...</DropdownMenu.Item>
          -->
        </DropdownMenu.SubContent>
      </DropdownMenu.Sub>

      <DropdownMenu.Separator />
      <DropdownMenu.Item class="text-red-500" on:click={onDelete}
        >Delete</DropdownMenu.Item
      >
    </DropdownMenu.Content>
  </DropdownMenu.Root>
{/if}

{#if showPreview && previewUrl}
  <FilePreview
    {file}
    bind:open={showPreview}
    {previewUrl}
    officeDocument={isOfficeDocument}
  />
{/if}
