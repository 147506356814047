<script lang="ts">
  import Button from '$lib/components/ui/button/button.svelte';
  import Icon from '@iconify/svelte';
  import AddPropertyButton from './add-property-button.svelte';
  import CustomFieldValue from './custom-field-value.svelte';

  export let editMode = false;
  export let designMode = false;
  export let customFields: any;
  export let fieldDefinitions: any[] = [];
  export let onAddField: (field: any) => void | Promise<void>;
  export let onDeleteField: (fieldName: string) => void | Promise<void>;
</script>

<div class=" p-2">
  <div class="grid gap-2">
    <div class="space-y-4">
      {#each fieldDefinitions as field}
        <div class="flex items-center gap-2">
          <CustomFieldValue
            {field}
            isEditable={editMode}
            bind:value={customFields[field.label]}
          />

          {#if designMode}
            <Button
              variant="ghost"
              size="icon"
              on:click={() => onDeleteField(field.label)}
            >
              <Icon icon="mdi:delete" class="w-4 h-4" />
            </Button>
          {/if}
        </div>
      {/each}
      {#if designMode}
        <AddPropertyButton
          {onAddField}
          class="w-full justify-start px-2 text-xs"
        />
      {/if}
    </div>
  </div>
</div>
