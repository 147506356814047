import { supabase } from '$lib/api/supabase';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';

export async function exportData(table: string, select: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from(table)
    .select(select);

  if (error) throw error;

  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${table}_export.json`;
  a.click();
  URL.revokeObjectURL(url);
}

export async function importData(table: string, file: File) {
  const text = await file.text();
  const data = JSON.parse(text);

  const { error } = await supabase(get(authStore)?.token)
    .from(table)
    .insert(data);

  if (error) throw error;
}
