<script lang="ts">
  import * as Dialog from '$lib/components/ui/dialog';
  import * as Select from '$lib/components/ui/select';
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';
  import { Switch } from '$lib/components/ui/switch';
  import { Label } from '$lib/components/ui/label';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import type { Pipeline } from '$lib/types/pipeline';
  import { getFieldValues } from '$lib/api/queries';

  export let showDialog = false;
  export let moduleTypes: Array<{ value: string; label: string }>;
  export let pipeline: Pipeline;
  export let onOpen: () => void;
  export let onClose: () => void;
  export let onCreate: (phases: Array<string>) => void;

  let fieldValues: Array<string> = [];
  let loading = false;

  // Define available fields for each module type
  const moduleFields = {
    accounts: [
      { value: 'status', label: 'Status' },
      { value: 'type', label: 'Type' },
      { value: 'industry', label: 'Industry' },
    ],
    contacts: [
      { value: 'status', label: 'Status' },
      { value: 'type', label: 'Type' },
      { value: 'position', label: 'Position' },
    ],
    deals: [
      { value: 'stage', label: 'Stage' },
      { value: 'status', label: 'Status' },
      { value: 'priority', label: 'Priority' },
    ],
    leads: [
      { value: 'status', label: 'Status' },
      { value: 'source', label: 'Source' },
      { value: 'rating', label: 'Rating' },
    ],
  };

  $: availableFields = pipeline.module
    ? moduleFields[pipeline.module] || []
    : [];
  $: isValid =
    pipeline.name &&
    pipeline.module &&
    (!pipeline.modifySource ||
      (pipeline.modifySource && pipeline.sourceColumn));

  async function loadFieldValues() {
    if (!pipeline.module || !pipeline.sourceColumn) {
      fieldValues = [];
      return;
    }

    loading = true;
    try {
      const values = await getFieldValues(
        pipeline.module,
        pipeline.sourceColumn,
      );
      fieldValues = values;
    } catch (error) {
      console.error('Failed to load field values:', error);
      fieldValues = [];
    } finally {
      loading = false;
    }
  }

  function handleCreate() {
    onCreate(fieldValues);
  }

  $: if (pipeline.sourceColumn) {
    loadFieldValues();
  }

  $: if (showDialog) {
    onOpen && onOpen();
  }
</script>

<Dialog.Root bind:open={showDialog}>
  <Dialog.Portal>
    <Dialog.Overlay class="fixed inset-0 bg-black/50" />
    <Dialog.Content
      class="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-[425px] bg-card rounded-lg p-6 shadow-lg"
    >
      <Dialog.Header>
        <Dialog.Title>Create New Pipeline</Dialog.Title>
        <Dialog.Description>
          Configure your new pipeline settings
        </Dialog.Description>
      </Dialog.Header>

      <div class="grid gap-4 py-4">
        <div class="grid gap-2">
          <Label for="name">Name</Label>
          <Input
            id="name"
            bind:value={pipeline.name}
            placeholder="Pipeline name"
            on:keydown={e => {
              e.stopPropagation();
            }}
          />
        </div>

        <div class="grid gap-2">
          <Label for="module-type">Module Type</Label>
          <HantaSelect
            bind:value={pipeline.module}
            placeholder="Select module type"
            options={moduleTypes?.map(m => ({
              value: m.module,
              label: m.title,
            }))}
            on:keydown={e => {
              e.stopPropagation();
            }}
          />
        </div>

        {#if false}
          <div class="flex flex-col gap-2">
            <div class="flex justify-between items-center">
              <Label for="modify-source">Modify Source</Label>
              <Switch id="modify-source" bind:checked={pipeline.modifySource} />
            </div>

            {#if pipeline.modifySource}
              <div class="grid gap-2">
                <Label for="source-column">Source Column</Label>
                <HantaSelect
                  bind:value={pipeline.sourceColumn}
                  placeholder="Select source column"
                  options={availableFields}
                  disabled={!pipeline.module}
                />
                {#if loading}
                  <div class="text-sm text-muted-foreground">
                    Loading field values...
                  </div>
                {:else if fieldValues.length > 0}
                  <div class="text-sm text-muted-foreground">
                    Found {fieldValues.length} values that will be used as phases
                  </div>
                {/if}
              </div>
            {/if}
          </div>
        {/if}
      </div>

      <Dialog.Footer>
        <Button variant="outline" on:click={onClose}>Cancel</Button>
        <Button
          variant="default"
          on:click={handleCreate}
          disabled={!isValid || loading}
        >
          Create Pipeline
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
