<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';

  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from '$lib/components/ui/dropdown-menu';
  import { Input } from '$lib/components/ui/input';
  import { Badge } from '$lib/components/ui/badge';
  import Icon from '@iconify/svelte';
  import { get, writable } from 'svelte/store';
  import FilterViewDialog from './filter-view-dialog.svelte';
  import FilterViewSubmenu from './filter-view-submenu.svelte';
  import {
    loadFilters,
    saveFilter,
    deleteFilter,
    updateFilter,
    togglePinned,
    toggleShared,
  } from '$lib/api/filter-api';
  import { authStore } from '$lib/stores/auth-store';
  import { toast } from 'svelte-sonner';
  import { standardFilters } from '$lib/config/standard-filters';
  import {
    createQuery,
    createMutation,
    useQueryClient,
  } from '@tanstack/svelte-query';
  import { getItemsCount } from '$lib/api/queries';

  const dispatch = createEventDispatcher();

  let selectedView = {
    name: 'Recently Viewed',
    filters: [],
    isDefault: true,
    isStandard: true,
  };
  let showNewViewDialog = false;
  let showEditViewDialog = false;
  let newViewName = '';
  let editViewName = '';
  let shareWithAll = false;
  let editShareWithAll = false;
  let filterToEdit = null;
  let filterSearchTerm = ''; // For searching through saved filters
  let hasUnsavedChanges = false;

  export let currentFilters: any[] = [];
  export let module: string = 'default';
  export let searchTerm = ''; // Current module search term
  export let fulltextsearch = false;
  export let sort = null; // Add sort prop

  const savedFilters = writable([]);
  const currentFiltersStore = writable(currentFilters);
  const filterCountsStore = writable<{ [key: string]: number }>({});

  $: currentFiltersStore.set(currentFilters);

  // Create query for filters
  $: filtersQuery = createQuery({
    queryKey: ['filters', module],
    queryFn: () => loadFilters(module),
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
  });

  // Update savedFilters store when query data changes
  $: if ($filtersQuery.data) {
    savedFilters.set($filtersQuery.data);
  }

  // Combine standard and saved filters
  $: allFilters = [
    ...standardFilters
      .filter(f => !f.module || f.module === module)
      .map(f => ({
        ...f,
        isStandard: true, // Mark as standard filter
        type: 'system',
        filter: f.filter,
      })),
    ...$savedFilters,
  ];

  // Filter views based on search and module
  $: filteredViews = allFilters.filter(
    f =>
      f?.name?.toLowerCase().includes(filterSearchTerm.toLowerCase()) &&
      (!f?.module || f.module === module),
  );

  function normalizeFilters(filters) {
    return (filters || []).map(f => ({
      field: f.field,
      operator: f.operator,
      value: f.value,
    }));
  }

  function getViewFilters(view) {
    // Handle both old 'filter' and new 'filters' fields
    return view?.filters || view?.filter || [];
  }

  function normalizeSort(sort) {
    if (!sort) return null;
    // Handle both field/direction and column/order structures
    return {
      field: sort.field || sort.column || null,
      direction: sort.direction || sort.order || null,
    };
  }

  $: {
    if (selectedView && !selectedView.isStandard) {
      const currentState = {
        filters: normalizeFilters(currentFilters),
        sort: normalizeSort(sort),
        search: searchTerm || '',
        fulltextsearch: !!fulltextsearch,
      };

      const savedState = {
        filters: normalizeFilters(getViewFilters(selectedView)),
        sort: normalizeSort(selectedView.sort),
        search: selectedView.search || '',
        fulltextsearch: !!selectedView.fulltextsearch,
      };

      hasUnsavedChanges =
        JSON.stringify(currentState) !== JSON.stringify(savedState);
    } else {
      hasUnsavedChanges = false;
    }
  }

  const handleAction = (event: MouseEvent, action: () => void) => {
    event.preventDefault();
    event.stopPropagation();
    action();
  };

  const queryClient = useQueryClient();

  // Create mutations
  const createFilterMutation = createMutation({
    mutationFn: variables => Promise.resolve(saveFilter(variables)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters', module] });
      toast.success('Filter saved successfully');
    },
    onError: err => {
      toast.error('Failed to save filter');
      console.error(err);
    },
  });

  const updateFilterMutation = createMutation({
    mutationFn: ({ id, filter }) => Promise.resolve(updateFilter(id, filter)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters', module] });
      toast.success('Filter updated successfully');
    },
    onError: err => {
      toast.error('Failed to update filter');
      console.error(err);
    },
  });

  const deleteFilterMutation = createMutation({
    mutationFn: id => Promise.resolve(deleteFilter(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters', module] });
      toast.success('Filter deleted successfully');
    },
    onError: err => {
      toast.error('Failed to delete filter');
      console.error(err);
    },
  });

  const togglePinMutation = createMutation({
    mutationFn: ({ id, isPinned }) =>
      Promise.resolve(togglePinned(id, isPinned)),
    onSuccess: (_, { isPinned }) => {
      queryClient.invalidateQueries({ queryKey: ['filters', module] });
      toast.success(isPinned ? 'Filter pinned' : 'Filter unpinned');
    },
    onError: err => {
      toast.error('Failed to update filter');
      console.error(err);
    },
  });

  const cloneFilterMutation = createMutation({
    mutationFn: filter => {
      const clonedFilter = {
        ...filter,
        id: undefined,
        name: `${filter.name} (Copy)`,
        type: 'private',
        isPinned: false,
      };
      return Promise.resolve(saveFilter(clonedFilter));
    },
    onSuccess: newFilter => {
      queryClient.invalidateQueries({ queryKey: ['filters', module] });
      toast.success('Filter cloned successfully');
      selectView(newFilter);
    },
    onError: err => {
      toast.error('Failed to clone filter');
      console.error(err);
    },
  });

  async function createNewView() {
    if (!newViewName) return;

    try {
      const filter = {
        name: newViewName,
        module,
        filters: currentFilters,
        type: 'private',
        isShared: shareWithAll,
        isPinned: false,
        search: searchTerm,
        fulltextsearch: fulltextsearch,
        sort: normalizeSort(sort),
      };

      $createFilterMutation.mutate(filter, {
        onSuccess: async () => {
          newViewName = '';
          shareWithAll = false;
          showNewViewDialog = false;

          // Get the latest filters and select the new one
          const latestFilters = await queryClient.fetchQuery([
            'filters',
            module,
          ]);
          const newFilter = latestFilters[latestFilters.length - 1];
          if (newFilter) {
            selectView(newFilter);
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function updateExistingView() {
    if (!editViewName || !filterToEdit) return;

    try {
      const filter = {
        ...filterToEdit,
        name: editViewName,
        isShared: editShareWithAll,
        sort: normalizeSort(sort),
      };

      $updateFilterMutation.mutate(
        { id: filter.id, filter },
        {
          onSuccess: () => {
            editViewName = '';
            editShareWithAll = false;
            showEditViewDialog = false;
            filterToEdit = null;
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteSavedFilter(filter) {
    if (!filter.isDefault) {
      try {
        $deleteFilterMutation.mutate(filter.id);
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function togglePin(filter) {
    try {
      $togglePinMutation.mutate({ id: filter.id, isPinned: !filter.isPinned });
    } catch (error) {
      console.error(error);
    }
  }

  async function cloneFilter(filter) {
    try {
      $cloneFilterMutation.mutate(filter);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveChanges() {
    if (!selectedView || selectedView.isStandard) return;

    try {
      const updatedFilter = {
        ...selectedView,
        filters: normalizeFilters(currentFilters),
        filter: null,
        sort: normalizeSort(sort),
        search: searchTerm || '',
        fulltextsearch: !!fulltextsearch,
      };

      $updateFilterMutation.mutate(
        { id: updatedFilter.id, filter: updatedFilter },
        {
          onSuccess: () => {
            const updatedView = {
              ...updatedFilter,
              filters: normalizeFilters(currentFilters),
              sort: normalizeSort(sort),
              search: searchTerm || '',
              fulltextsearch: !!fulltextsearch,
            };

            selectedView = updatedView;
            hasUnsavedChanges = false;
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  function selectView(view) {
    selectedView = view;
    dispatch('filters', {
      filters: getViewFilters(view),
      search: view.search || '',
      fulltextsearch: view.fulltextsearch || false,
      sort: view.sort || sort || null, // Use view's sort if available, fallback to current sort
    });
  }

  function openEditDialog(filter) {
    filterToEdit = filter;
    editViewName = filter.name;
    editShareWithAll = filter.isShared;
    showEditViewDialog = true;
  }

  // Function to fetch count for a specific filter
  async function fetchFilterCount(filter) {
    if (filter.isStandard || !filter.isDefault) {
      try {
        const count = await getItemsCount({
          module,
          filters: getViewFilters(filter),
          fulltextsearch: filter.fulltextsearch || false,
          search: filter.search || '',
          sort: filter.sort || null,
        });

        filterCountsStore.update(counts => ({
          ...counts,
          [filter.name]: count,
        }));
      } catch (error) {
        console.error('Failed to fetch filter count:', error);
      }
    }
  }

  // Reactive statement to fetch counts for pinned filters
  $: {
    if (module && filteredViews.length > 0) {
      filteredViews.filter(f => f.isPinned).forEach(fetchFilterCount);
    }
  }

  // Ensure counts are fetched when filters are loaded
  $: if ($filtersQuery.data) {
    filteredViews.filter(f => f.isPinned).forEach(fetchFilterCount);
  }

  $: isLoading = $filtersQuery.isLoading;
  $: isSaving =
    $createFilterMutation.isPending ||
    $updateFilterMutation.isPending ||
    $deleteFilterMutation.isPending ||
    $togglePinMutation.isPending ||
    $cloneFilterMutation.isPending;

  $: if (module) {
    queryClient.invalidateQueries(['filters', module]);
  }

  onMount(() => {
    queryClient.invalidateQueries(['filters', module]);
  });

  $: hasPinnedFilters = allFilters.filter(f => f.isPinned)?.length > 1;

  function formatLargeNumber(num) {
    if (num >= 1000000000) {
      return `${(num / 1000000000).toFixed(1)}B`;
    } else if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    } else {
      return num.toString();
    }
  }
</script>

{#if isLoading}
  <div class="flex items-center justify-center p-4">
    <span class="loading loading-spinner loading-md"></span>
  </div>
{:else}
  {#if hasPinnedFilters}
    <div class="fixed top-[0px] left-[30%]">
      <div class="flex flex-col gap-4 py-2">
        <!-- Header -->
        <div class="flex justify-between items-center">
          <div class="flex items-center gap-2">
            <Icon icon="mdi:pin" class="size-4" />
            <span class="font-medium font-inter text-xl">Pinned Filters</span>
          </div>
        </div>

        <!-- Pinned Views -->
        <div class="flex flex-wrap gap-2">
          {#each filteredViews.filter(f => f.isPinned) as filter}
            <Button
              variant={selectedView.id === filter.id ? 'default' : 'outline'}
              size="sm"
              class="gap-2 whitespace-nowrap p-6 relative min-w-36"
              on:click={e => handleAction(e, () => selectView(filter))}
            >
              <span>{filter.name}</span>
              {#if filter.isShared}
                <Badge variant="default" class=" px-1 py-0 text-xs ">
                  <Icon icon="mdi:account-group" class="size-3" />
                </Badge>
              {/if}

              {#if $filterCountsStore[filter.name] !== undefined}
                <Badge
                  size="sm"
                  variant="default"
                  class="ml-1 absolute -top-2 right-0 font-thin border "
                >
                  {formatLargeNumber($filterCountsStore[filter.name])}
                </Badge>
              {/if}
            </Button>
          {/each}
        </div>
      </div>
    </div>
  {/if}
  <div class="w-full">
    <DropdownMenu>
      <DropdownMenuTrigger asChild let:builder>
        <Button
          variant="outline"
          builders={[builder]}
          class="gap-2 justify-between text-base w-full {hasUnsavedChanges
            ? 'border-orange-500/30 text-orange-600'
            : ''}"
        >
          <div class="flex items-center gap-2">
            <Icon icon="mdi:filter-variant" class="size-4" />
            <span class="font-medium font-inter">
              {selectedView.name}
              {#if hasUnsavedChanges}
                <span class="text-orange-500 ml-1">*</span>
              {/if}
            </span>
          </div>
          <Icon
            icon={hasUnsavedChanges
              ? 'mdi:content-save-alert'
              : 'mdi:chevron-down'}
            class="size-4 opacity-50 {hasUnsavedChanges
              ? 'text-orange-500'
              : ''}"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        side="right"
        sideOffset={8}
        class="w-72 shadow-lg rounded-lg  bg-popover font-inter "
      >
        <div class="p-2 space-y-1 border shadow-md">
          <div class="flex items-center px-2 text-base text-muted-foreground">
            <Icon icon="mdi:magnify" class="size-4 mr-2" />
            <Input
              type="text"
              placeholder="Search views..."
              class="h-9 bg-transparent border-none focus-visible:ring-transparent text-base"
              bind:value={filterSearchTerm}
            />
          </div>

          {#if hasUnsavedChanges}
            <div class="px-2 mb-1">
              <Button
                variant="outline"
                class="w-full justify-start gap-2 text-orange-600 border-orange-500/30 hover:border-orange-500/50 hover:bg-orange-500/10"
                on:click={saveChanges}
              >
                <Icon icon="mdi:content-save-outline" class="size-4" />
                <span>Save Changes to "{selectedView.name}"</span>
              </Button>
            </div>
          {/if}
        </div>

        <div class="overflow-y-scroll max-h-[70svh]">
          <!-- Pinned Views -->
          {#each filteredViews.filter(f => f.isPinned) as filter}
            <div class="group px-2 py-0.5">
              <div
                class="flex justify-between items-center px-2 py-1 rounded-md outline-none cursor-default hover:bg-muted hover:text-accent-foreground"
              >
                <Button
                  variant="ghost"
                  class="flex items-center justify-start gap-1 w-full text-sm text-left truncate {filter.isStandard
                    ? 'font-semibold text-primary/80 hover:text-primary'
                    : ''} px-1.5 py-1 h-auto"
                  on:click={e => handleAction(e, () => selectView(filter))}
                >
                  <Icon
                    icon={filter.isStandard ? 'mdi:star' : 'mdi:pin'}
                    class="size-3.5 {filter.isStandard
                      ? 'text-yellow-500'
                      : 'text-blue-500'} shrink-0 mr-1"
                  />
                  <span class="truncate overflow-hidden">{filter.name}</span>
                  {#if filter.isShared}
                    <Badge
                      variant="outline"
                      class="ml-auto py-0 px-1 text-xs shrink-0"
                    >
                      <Icon icon="mdi:account-group" class="size-4" />
                    </Badge>
                  {/if}
                </Button>
                <FilterViewSubmenu
                  {filter}
                  {handleAction}
                  onTogglePin={togglePin}
                  onClone={cloneFilter}
                  onDelete={deleteSavedFilter}
                  onEdit={openEditDialog}
                />
              </div>
            </div>
          {/each}

          <!-- Unpinned Views -->
          {#each filteredViews.filter(f => !f.isPinned) as filter}
            <div class="group px-2 py-0.5">
              <div
                class="flex justify-between items-center px-2 py-1 rounded-md outline-none cursor-default hover:bg-muted hover:text-accent-foreground"
              >
                <Button
                  variant="ghost"
                  class="flex items-center justify-start gap-1 w-full text-sm text-left truncate {filter.isStandard
                    ? 'font-semibold text-primary/80 hover:text-primary'
                    : ''} px-1.5 py-1 h-auto"
                  on:click={e => handleAction(e, () => selectView(filter))}
                >
                  <Icon
                    icon={filter.isStandard ? 'mdi:star' : 'mdi:filter'}
                    class="size-3.5 {filter.isStandard
                      ? 'text-yellow-500'
                      : 'opacity-50'} shrink-0 mr-1"
                  />
                  <span class="truncate overflow-hidden">{filter.name}</span>
                  {#if filter.isShared}
                    <Badge
                      variant="outline"
                      class="ml-auto py-0 px-1 text-xs shrink-0"
                    >
                      <Icon icon="mdi:account-group" class="size-2.5" />
                    </Badge>
                  {/if}
                </Button>
                {#if !filter.isStandard}
                  <FilterViewSubmenu
                    {filter}
                    {handleAction}
                    onTogglePin={togglePin}
                    onClone={cloneFilter}
                    onDelete={deleteSavedFilter}
                    onEdit={openEditDialog}
                  />
                {/if}
              </div>
            </div>
          {/each}
        </div>
        <DropdownMenuSeparator />
        <!-- Create New View -->
        <div class="px-2 py-1">
          <Button
            variant="ghost"
            class="flex items-center justify-start gap-2 w-full text-base text-left truncate outline-none cursor-pointer hover:bg-muted hover:text-accent-foreground"
            on:click={() => (showNewViewDialog = true)}
          >
            <Icon icon="mdi:plus" class="size-4 mr-2 shrink-0" />
            <span
              >{currentFilters?.length
                ? 'Save Current Filter'
                : 'New View'}</span
            >
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>

  <!-- New View Dialog -->
  <FilterViewDialog
    bind:open={showNewViewDialog}
    mode="create"
    bind:viewName={newViewName}
    bind:shareWithAll
    onSubmit={createNewView}
    onCancel={() => (showNewViewDialog = false)}
  />

  <!-- Edit View Dialog -->
  <FilterViewDialog
    bind:open={showEditViewDialog}
    mode="edit"
    bind:viewName={editViewName}
    bind:shareWithAll={editShareWithAll}
    onSubmit={updateExistingView}
    onCancel={() => (showEditViewDialog = false)}
  />
{/if}
