<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import { Switch } from '$lib/components/ui/switch';
  import { Label } from '$lib/components/ui/label';
  import PricelistTable from './pricelist-table.svelte';
  import { Button } from '$lib/components/ui/button';
  import Dimensions from './dimensions.svelte';
  import { toast } from 'svelte-sonner';

  export let pricelist: any;

  const removePricelist = () => {
    console.debug('removePricelist', pricelist);
  };

  function handleGeneratePriceGrid(event: CustomEvent) {
    const { priceEntries } = event.detail;

    // Initialize prices array if it doesn't exist
    if (!pricelist.prices) {
      pricelist.prices = [];
    }

    // Create a map of existing prices for quick lookup
    const existingPrices = new Map(
      pricelist.prices.map(price => {
        const key = Object.entries(price)
          .filter(([key]) => pricelist.dimensions.some(dim => dim.name === key))
          .map(([_, value]) => value)
          .join('|');
        return [key, price];
      }),
    );

    // Create new prices array with preserved values
    const newPrices = priceEntries.map(entry => {
      const key = Object.entries(entry)
        .filter(([key]) => pricelist.dimensions.some(dim => dim.name === key))
        .map(([_, value]) => value)
        .join('|');

      const existing = existingPrices.get(key);
      if (existing) {
        return {
          ...entry,
          price: existing.price ?? 0,
          minPrice: existing.minPrice ?? 0,
          unitPrice: existing.unitPrice ?? 0,
          unit: existing.unit ?? '',
          percent: existing.percent ?? 0,
        };
      }
      return entry;
    });

    // Force reactivity by creating a new array
    pricelist.prices = [...newPrices];

    // Force a refresh of the pricelist
    pricelist = { ...pricelist };
    toast.success('Prices updated successfully');
  }
</script>

{#if pricelist}
  {#key pricelist?.name}
    <div
      class="flex absolute right-0 -top-4 justify-between items-center mt-4 space-x-4"
    >
      <div class="flex items-center space-x-2">
        <Switch id="active" bind:checked={pricelist.active} />
        <Label for="active">Active</Label>
      </div>
      <Button variant="destructive" size="sm" on:click={removePricelist}
        >Remove Pricelist</Button
      >
    </div>
    <Tabs.Root value="dimensions">
      <Tabs.List>
        <Tabs.Trigger value="dimensions">Dimensions</Tabs.Trigger>
        <Tabs.Trigger value="prices">Prices</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="dimensions">
        <Dimensions
          bind:dimensions={pricelist.dimensions}
          on:generatePriceGrid={handleGeneratePriceGrid}
        />
      </Tabs.Content>
      <Tabs.Content value="prices">
        {#key pricelist.prices?.length}
          <PricelistTable
            dimensions={pricelist?.dimensions}
            bind:data={pricelist.prices}
          />
        {/key}
      </Tabs.Content>
    </Tabs.Root>
  {/key}
{/if}
