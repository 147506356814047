<script lang="ts">
  import { Label } from '$lib/components/ui/label';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import type { PipelineItem } from '$lib/types/pipeline';
  import Files from '$lib/components/hanta/files/files.svelte';
  import Comments from '$lib/components/hanta/comments/comments.svelte';
  import CustomFields from '$lib/components/hanta/custom-fields/custom-fields.svelte';
  import Button from '$lib/components/ui/button/button.svelte';

  export let pipeline: any;
  export let item: PipelineItem;
  $: fieldDefinitions = pipeline?.customFields?.fieldDefinitions || [];

  $: if (item && !item.customFields) {
    item.customFields = {};
  }

  export let onClose: () => void;

  function onAddField(field: any) {
    pipeline.customFields = {
      ...pipeline.customFields,

      fieldDefinitions: [
        ...fieldDefinitions,
        {
          id: crypto.randomUUID(),
          label: field.label,
          type: field.type,
          required: field.required || false,
          defaultValue: field.defaultValue,
        },
      ],
    };

    pipelineStore.updatePipeline(pipeline);
  }

  function onDeleteField(fieldName: string) {
    pipeline.customFields = {
      ...pipeline.customFields,
      fieldDefinitions: pipeline.customFields.fieldDefinitions.filter(
        field => field.label !== fieldName,
      ),
    };
    pipelineStore.updatePipeline(pipeline);
  }

  async function onSave() {
    const currentPipeline = $pipelineStore.find(p => p.id === pipeline.id);
    if (!currentPipeline) return;

    // Find and update the phase containing our item
    const updatedPhases = currentPipeline.phases.map(phase => {
      if (phase.id === item.phaseId) {
        return {
          ...phase,
          items: phase.items.map(i => (i.id === item.id ? item : i)),
        };
      }
      return phase;
    });

    // Update the pipeline with new phases
    const updatedPipeline = {
      ...currentPipeline,
      phases: updatedPhases,
    };

    // Update the store
    await pipelineStore.updatePipeline(updatedPipeline);

    if (onClose) onClose();
  }
</script>

<div class="flex justify-end mt-4 space-x-2">
  <Button variant="ghost" on:click={onClose}>Cancel</Button>
  <Button variant="default" on:click={onSave}>Save</Button>
</div>
<div class="grid grid-cols-2 gap-6 py-4 max-w-4xl mx-auto">
  <div class="col-span-1 bg-secondary p-4">
    {#if item && item.customFields}
      <CustomFields
        editMode={true}
        designMode={true}
        bind:customFields={item.customFields}
        {fieldDefinitions}
        {onAddField}
        {onDeleteField}
      />
    {/if}

    <div class="col-span-1 p-4">
      <!-- Files -->
      <div class="my-4">
        <Label>Files</Label>
        <Files
          folder={`pipelines/${item?.pipeline?.id}/items/${item?.id}`}
          compact={true}
          title=""
          withPreviewImages={true}
          maxColumns={1}
          uploadOnEnd={true}
        />
      </div>
    </div>
  </div>

  <div class="col-span-1 p-4">
    <Comments itemId={item.id} module="pipelineItems" />
  </div>
</div>
