<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import * as Popover from '$lib/components/ui/popover';
  import * as Input from '$lib/components/ui/input';
  import Icon from '@iconify/svelte';
  import type { CustomField } from '$lib/types/pipeline';
  import FieldSettings from './field-settings.svelte';
  import { cn } from '$lib/utils';

  export let onAddField: (field: Partial<CustomField>) => void;
  export let className: string = '';

  export { className as class };

  let open = false;
  let search = '';
  let selectedField: Partial<CustomField> | null = null;

  const fieldTypes = [
    {
      label: 'Text',
      value: 'text',
      icon: 'mdi:text',
      description: 'Plain text, numbers, or symbols',
    },
    {
      label: 'Number',
      value: 'number',
      icon: 'mdi:numeric',
      description: 'Numbers only',
    },
    {
      label: 'Currency',
      value: 'currency',
      icon: 'mdi:currency-usd',
      description: 'Monetary values',
    },
    {
      label: 'Percentage',
      value: 'percentage',
      icon: 'mdi:percent',
      description: 'Percentage values',
    },
    {
      label: 'Date',
      value: 'date',
      icon: 'mdi:calendar',
      description: 'Date picker',
    },
    {
      label: 'Boolean',
      value: 'boolean',
      icon: 'mdi:check-circle',
      description: 'Yes/No, True/False',
    },
    {
      label: 'URL',
      value: 'url',
      icon: 'mdi:link',
      description: 'Web links',
    },
    {
      label: 'Email',
      value: 'email',
      icon: 'mdi:email',
      description: 'Email addresses',
    },
    {
      label: 'Phone',
      value: 'phone',
      icon: 'mdi:phone',
      description: 'Phone numbers',
    },
  ];

  $: filteredTypes = fieldTypes.filter(type => {
    const searchMatch =
      search.length === 0 ||
      type.label.toLowerCase().includes(search.toLowerCase()) ||
      type.description.toLowerCase().includes(search.toLowerCase());
    return searchMatch;
  });

  function handleSelect(type: any) {
    selectedField = {
      type: type.value,
      label: type.label,
    };
  }

  function handleFieldUpdate(field: Partial<CustomField>) {
    selectedField = field;
  }

  function handleAddField() {
    if (selectedField) {
      onAddField(selectedField);
      selectedField = null;
      open = false;
      search = '';
    }
  }

  function handleDuplicate() {
    if (selectedField) {
      const newField = { ...selectedField };
      newField.label = `${newField.label} copy`;
      selectedField = newField;
    }
  }
</script>

<div class="w-full">
  <Popover.Root bind:open>
    <Popover.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="ghost"
        class={cn(className, 'mt-12')}
      >
        <Icon icon="mdi:plus" class="mr-1 size-3" />
        Add new property
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-[320px]" align="start" sideOffset={5}>
      {#if !selectedField}
        <div class="p-2 space-y-2">
          <Input.Input
            type="text"
            placeholder="Search for a property type..."
            bind:value={search}
          />
          <div class="max-h-[300px] overflow-y-auto space-y-1">
            {#each filteredTypes as type}
              <button
                class="w-full flex items-center gap-2 p-2 hover:bg-muted rounded-md transition-colors"
                on:click={() => handleSelect(type)}
              >
                <div
                  class="flex size-8 items-center justify-center rounded-lg border bg-muted"
                >
                  <Icon icon={type.icon} class="size-4" />
                </div>
                <div class="flex flex-col text-left">
                  <div class="text-sm font-medium">{type.label}</div>
                  <div class="text-xs text-muted-foreground">
                    {type.description}
                  </div>
                </div>
              </button>
            {/each}
          </div>
        </div>
      {:else}
        <div class="flex items-center justify-between p-2 border-b">
          <button
            class="hover:bg-muted p-1 rounded-sm"
            on:click={() => (selectedField = null)}
          >
            <Icon icon="mdi:arrow-left" class="size-4" />
          </button>
          <Button variant="default" on:click={handleAddField}>Add field</Button>
        </div>
        <FieldSettings
          field={selectedField}
          onUpdate={handleFieldUpdate}
          onDelete={() => (open = false)}
          onDuplicate={handleDuplicate}
        />
      {/if}
    </Popover.Content>
  </Popover.Root>
</div>
