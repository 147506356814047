<script lang="ts">
  import * as Card from '$lib/components/ui/card';
  import PhasesList from './phases-list.svelte';
  import KanbanHint from './kanban-hint.svelte';
  import type { Phase } from '$lib/types/pipeline';
  import type { Pipeline } from '$lib/types/pipeline';
  import PipelinePhaseListItems from './pipeline-phase-list-items.svelte';
  import { addPipelineItem } from '$lib/api/pipeline-api';
  import PipelineToolbar from './pipeline-toolbar.svelte';

  export let pipeline: Pipeline;
  export let isPreviewMode = false;
  export let onPhaseAdd: () => void;
  export let onPhaseRemove: (id: string) => void;
  export let onPhaseReorder: (phases: Phase[]) => void;
  export let onSavePipeline: () => void;
  export let onChange: (phase: Phase, items: any[]) => void;

  let searchQuery = '';
  let sortField: string | null = null;
  let sortDirection: 'asc' | 'desc' = 'asc';
  let activeFilters: Record<string, any> = {};

  let phases = pipeline.phases || [];
  $: {
    phases = pipeline.phases || [];
  }

  // Filter and sort pipeline items
  $: filteredPhases = filterAndSortPhases(
    phases,
    searchQuery,
    sortField,
    sortDirection,
    activeFilters,
  );

  function filterAndSortPhases(
    phases: Phase[],
    query: string,
    sortField: string | null,
    sortDirection: 'asc' | 'desc',
    filters: Record<string, any>,
  ): Phase[] {
    return phases.map(phase => ({
      ...phase,
      items: filterAndSortItems(
        phase.items || [],
        query,
        sortField,
        sortDirection,
        filters,
      ),
    }));
  }

  function filterAndSortItems(
    items: any[],
    query: string,
    sortField: string | null,
    sortDirection: 'asc' | 'desc',
    filters: Record<string, any>,
  ): any[] {
    let filtered = [...items];

    // Apply search
    if (query) {
      const lowerQuery = query.toLowerCase();
      filtered = filtered.filter(item => {
        // Search in name
        if (item.name?.toLowerCase().includes(lowerQuery)) return true;

        // Search in custom fields
        if (item.customFields) {
          return Object.values(item.customFields).some(value =>
            String(value).toLowerCase().includes(lowerQuery),
          );
        }
        return false;
      });
    }

    // Apply filters
    Object.entries(filters).forEach(([field, value]) => {
      filtered = filtered.filter(item => item.customFields?.[field] === value);
    });

    // Apply sort
    if (sortField) {
      filtered.sort((a, b) => {
        const aValue = a.customFields?.[sortField];
        const bValue = b.customFields?.[sortField];

        if (aValue === bValue) return 0;
        if (aValue === undefined) return 1;
        if (bValue === undefined) return -1;

        const result = aValue < bValue ? -1 : 1;
        return sortDirection === 'asc' ? result : -result;
      });
    }

    return filtered;
  }

  const onChanges = (
    phase: Phase,
    e: { items: any[]; added: any[]; removed: any[] },
  ) => {
    console.log('onChanges ', phase?.name, e);
    phase.items = e.items;
    if (e.added.length) {
      onChange(phase, e.items);
    }
  };

  async function onAddItem(phase: Phase, item: any) {
    if (pipeline.modifySource) {
      pipeline.phases.find(p => p.id === phase.id)?.items.push(item);
    } else {
      // insert into pipelineitems
      const newItem = await addPipelineItem(phase.id, pipeline.module, item);
      if (newItem) {
        const phaseToUpdate = phases.find(p => p.id === phase.id);
        if (phaseToUpdate) {
          phaseToUpdate.items = [...(phaseToUpdate.items || []), newItem];
          phases = [...phases]; // Trigger reactivity
        }
      }
    }
  }

  function handleSearch(event: CustomEvent) {
    searchQuery = event.detail.query;
  }

  function handleSort(event: CustomEvent) {
    sortField = event.detail.field;
    sortDirection = event.detail.direction;
  }

  function handleFilter(event: CustomEvent) {
    const { field, value } = event.detail;
    activeFilters = { ...activeFilters, [field]: value };
  }

  function handleClearFilter(event: CustomEvent) {
    const { field } = event.detail;
    const { [field]: _, ...rest } = activeFilters;
    activeFilters = rest;
  }

  function handleClearAllFilters() {
    activeFilters = {};
  }
</script>

<div class="flex flex-col px-2">
  {#if isPreviewMode}
    <div class="absolute top-0 right-12">
      <PipelineToolbar
        {pipeline}
        {searchQuery}
        {sortField}
        {sortDirection}
        {activeFilters}
        on:search={handleSearch}
        on:sort={handleSort}
        on:filter={handleFilter}
        on:clearFilter={handleClearFilter}
        on:clearAllFilters={handleClearAllFilters}
      />
    </div>
  {/if}
  <div class="flex flex-row gap-0.5 flex-1 overflow-scroll relative">
    {#if isPreviewMode}
      {#each filteredPhases || [] as phase}
        <PipelinePhaseListItems
          items={phase.items}
          {pipeline}
          {phase}
          on:changes={e => onChanges(phase, e.detail)}
          on:add={e => onAddItem(phase, e.detail)}
        />
      {/each}
    {:else}
      <div class="p-0">
        <PhasesList
          {pipeline}
          {isPreviewMode}
          {onPhaseRemove}
          {onPhaseReorder}
          {onPhaseAdd}
          {onSavePipeline}
        />
      </div>
    {/if}
  </div>
</div>
<div class="hidden">
  <KanbanHint />
</div>
