<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  export let values: string;
</script>

{#if values}
  <Button
    variant="ghost"
    size="icon"
    href={values}
    class="text-primary"
    target={'_blank'}
  >
    <Icon icon="bxs:badge-check" class="text-primary size-5" />
  </Button>
{/if}
