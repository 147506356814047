<script lang="ts">
  import { popupStore } from '$lib/stores/app-store';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import KanbanItemDetails from './kanban-item-details.svelte';

  export let item;
  let pipeline;

  $: if (item && $pipelineStore?.length > 0) {
    pipeline = $pipelineStore?.find(p =>
      p.phases?.some(phase => phase.id === item.phaseId),
    );
  } else {
    pipeline = null;
  }
</script>

{#if pipeline}
  <KanbanItemDetails
    {item}
    {pipeline}
    onClose={() => popupStore.closePopup()}
  />
{/if}
