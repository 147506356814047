<script lang="ts">
  import Icon from '@iconify/svelte';
  import { Button } from '$lib/components/ui/button';
  import packageImage from '../../../assets/package.png';

  export let packages = [];
  export let volumeFactor;
  export let readOnly = false;

  $: volumeM3 = calculateVolumeM3(packages);
  $: volumeWeight = calculateVolumeWeight(packages) * volumeFactor;

  function calculateVolumeM3(packages) {
    return (packages || []).reduce((total, pkg) => {
      try {
        const { plength, pwidth, pheight, pquantity } = pkg;
        if (plength && pwidth && pheight && pquantity) {
          return total + (plength * pwidth * pheight * pquantity) / 1000000; // Convert cm³ to m³
        }
        return total;
      } catch (error) {
        console.error(error);
        return total;
      }
    }, 0);
  }

  function calculateVolumeWeight(packages) {
    return (packages || [])
      .reduce((total, pkg) => {
        const { plength, pwidth, pheight, pquantity } = pkg;
        if (plength && pwidth && pheight && pquantity) {
          return total + (plength * pwidth * pheight * pquantity) / 1000000;
        }
        return total;
      }, 0)
      .toFixed(2);
  }

  function addPackage() {
    packages = [
      ...(packages || []),
      { plength: 100, pwidth: 100, pheight: 100, pquantity: 1, pweight: 1 },
    ];
  }

  function removePackage(index) {
    packages = packages.filter((_, i) => i !== index);
  }
</script>

<div class="space-y-4">
  {#each packages || [] as pkg, index}
    {#if index === 0}
      <img alt="Package" src={packageImage} class="ml-auto w-36" />
    {/if}
    <p class="text-base font-bold">Package #{index + 1}</p>
    <div class="grid grid-cols-6 gap-4 items-end">
      <div class="flex flex-col">
        <label for={`quantity-${index}`}>Quantity</label>

        <input
          type="number"
          id={`quantity-${index}`}
          bind:value={pkg.pquantity}
          disabled={readOnly}
        />
      </div>
      <div class="flex flex-col">
        <label for={`length-${index}`}>Length (cm)</label>
        <input
          type="number"
          id={`length-${index}`}
          bind:value={pkg.plength}
          disabled={readOnly}
        />
      </div>
      <div class="flex flex-col">
        <label for={`width-${index}`}>Width (cm)</label>
        <input
          type="number"
          id={`width-${index}`}
          bind:value={pkg.pwidth}
          disabled={readOnly}
        />
      </div>
      <div class="flex flex-col">
        <label for={`height-${index}`}>Height (cm)</label>
        <input
          type="number"
          id={`height-${index}`}
          bind:value={pkg.pheight}
          disabled={readOnly}
        />
      </div>

      <Button
        variant="ghost"
        on:click={() => removePackage(index)}
        size="icon"
        disabled={readOnly}
      >
        <Icon icon="mdi:minus" class="size-4" />
      </Button>
    </div>
  {/each}
</div>

<div class="flex justify-between items-center mt-4">
  <Button on:click={addPackage} disabled={readOnly}>
    <Icon icon="mdi:plus" class="mr-2 size-4" />
    Add Package</Button
  >

  {#if packages?.length > 0}
    <div class="text-xl">
      <p>Volume (m³): {volumeM3}</p>
      <p>Volume Weight (kg): {volumeWeight?.toFixed(1)}</p>
    </div>
  {/if}
</div>
