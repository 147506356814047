<script lang="ts">
  import { Skeleton } from '$lib/components/ui/skeleton';

  import { findAssignments, getLov } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { derived, writable } from 'svelte/store';
  import { createQuery } from '@tanstack/svelte-query';

  import DocxTemplates from './../../../widgets/docx-template/docx-templates.svelte';
  import ReportChartBar from './report-chart-bar.svelte';
  import ReportChartPie from './report-chart-pie.svelte';

  export let deal;
  let phases = [];

  let data;

  const templateName = 'report.docx';

  $: if (deal && $query.isSuccess && $query.data) {
    data = {
      name: 'Report',
      date: new Date().toLocaleDateString(),
      deal: deal.name,
      allPhases: phases.map(phase => ({
        name: phase,
        count: $query.data[phase]?.length || 0,
      })),
      rejects: ($query.data[phases[phases.length - 1]] ?? []).sort((a, b) =>
        (a ?? '').rejectedBy?.localeCompare((b ?? '').rejectedBy),
      ),

      rejectsBy: Object.entries(
        $query.data[phases[phases.length - 1]]?.reduce((acc, el) => {
          const key = el.rejectedBy || 'n/a';
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key]++;
          return acc;
        }, {}) || {},
      ).map(([name, count]) => ({
        name,
        count,
      })),
      rejectsReason: Object.entries(
        $query.data[phases[phases.length - 1]]?.reduce((acc, el) => {
          const key = el.rejectedReason ?? 'n/a';
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key]++;
          return acc;
        }, {}) || {},
      ).map(([name, count]) => ({
        name,
        count,
      })),

      total: Object.values($query.data).reduce((acc, el) => acc + el.length, 0),
      phases: $query.data,
      allCandidates: Object.values($query.data)
        .reduce((acc, el) => acc.concat(el), [])
        .map(candidate => ({
          name: candidate?.candidates[0]?.name || 'n/a',
          status: candidate.status,
        }))
        .filter(a => a.status !== phases[phases.length - 1])
        .sort((a, b) => phases.indexOf(b.status) - phases.indexOf(a.status)),
      rejectedCandidates: Object.values($query.data)
        .reduce((acc, el) => acc.concat(el), [])
        .map(candidate => ({
          name: candidate?.candidates[0]?.name || 'n/a',
          status: candidate.status,
        }))
        .filter(a => a.status === phases[phases.length - 1]),
      customer: {
        name: deal.customerObj.name,
        logo: deal.customerObj.logo,
      },
    };
  }

  const idStore = writable(deal?.id);
  $: idStore.set(deal?.id);

  const query = createQuery(
    derived([idStore], ([$id]) => ({
      enabled: !!$id,
      queryKey: ['assignments', $id],
      queryFn: async () => {
        const result = await findAssignments({
          id: $id,
          module: 'deals',
          search: '',
          page: 1,
          itemsPerPage: 1000,
        });

        // group by status
        return result.data.reduce((acc, item) => {
          if (!acc[item.status]) {
            acc[item.status] = [];
          }
          acc[item.status].push(item);
          return acc;
        }, {});
      },
    })),
  );

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);
  });

  let chart1;
  let chart2;
  let chart3;
  let chart4;
</script>

{#if $query.isLoading}
  <Skeleton class="h-[20px] w-full rounded-full" />
{:else if $query.isError}
  <div>Error: {$query.error.message}</div>
{:else if $query.isSuccess}
  {#if chart1 && chart2 && chart3 && chart4}
    <DocxTemplates
      class="w-64 px-4 py-2"
      data={{
        ...data,
        chart1,
        chart2,
        chart3,
        chart4,
      }}
      label="Report"
      {templateName}
    />
  {:else}
    <Skeleton class="h-[20px] w-full rounded-full" />
  {/if}
  <div class="hidden">
    <pre>{JSON.stringify(data, null, 2)}</pre>
    <ReportChartBar data={data.allPhases} bind:chartAsSvg={chart1} />
    <ReportChartPie data={data.allPhases} bind:chartAsSvg={chart2} />
    <ReportChartPie data={data.rejectsBy} bind:chartAsSvg={chart3} />
    <ReportChartPie data={data.rejectsReason} bind:chartAsSvg={chart4} />
  </div>
{/if}
