<script lang="ts">
  import * as Checkbox from '$lib/components/ui/checkbox';

  import Icon from '@iconify/svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import type { Pipeline } from '$lib/types/pipeline';
  import { formatDistanceToNow, format } from 'date-fns';
  import CustomFieldValue from '$lib/components/hanta/custom-fields/custom-field-value.svelte';
  import PipelineItemMenu from './pipeline-item-menu.svelte';
  import CustomFields from '../hanta/custom-fields/custom-fields.svelte';
  import Button from '../ui/button/button.svelte';
  import { popupStore } from '$lib/stores/app-store';

  export let item;
  export let pipeline: Pipeline;
  let checked = false;

  function getItemDetails() {
    if (pipeline?.module === 'accounts') {
      return {
        name: item?.name || 'Unknown Account',
        subtitle: item?.account?.industry || '',
        amount: item?.amount ? `$${item.amount.toLocaleString()}` : '',
        website: item?.account?.website || '',
        location: item?.account?.location || '',
        status: item?.status || '',
      };
    } else if (pipeline?.module === 'contacts') {
      return {
        name:
          item?.contact?.name || item?.candidateObj?.name || 'Unknown Contact',
        subtitle: item?.contact?.title || item?.candidateObj?.title || '',
        company: item?.contact?.company || item?.candidateObj?.company || '',
        email: item?.contact?.email || item?.candidateObj?.email || '',
        phone: item?.contact?.phone || item?.candidateObj?.phone || '',
        status: item?.status || '',
      };
    } else if (pipeline?.module === 'deals') {
      return {
        name: item?.name || 'Unknown Deal',
        subtitle: item?.stage || '',
        amount: item?.amount ? `$${item.amount.toLocaleString()}` : '',
        company: item?.account?.name || '',
        probability: item?.probability ? `${item.probability}%` : '',
        status: item?.status || '',
      };
    }
    return {
      name: item?.name || 'Unknown Item',
      subtitle: '',
      status: item?.status || '',
    };
  }

  // Helper to get custom field value
  function getCustomFieldValue(fieldId: string) {
    return item.data?.[fieldId];
  }

  // Helper to update custom field value
  async function updateFieldValue(fieldId: string, value: any) {
    if (!item.data) {
      item.data = {};
    }
    item.data[fieldId] = value;
    // Here you would typically call your API to update the item
    // await updatePipelineItem(item);
  }

  $: details = getItemDetails();
  $: daysInStatus = formatDistanceToNow(
    new Date(item.statusChangedAt || item.createdAt),
  );
  $: notesCount = item.data?.notes?.length || 0;
  $: commentsCount = item.data?.comments?.length || 0;
  $: tasksCount = item.data?.tasks?.length || 0;
  $: completedTasksCount =
    item.data?.tasks?.filter(t => t.completed)?.length || 0;
  $: lastActivity = item.lastActivity
    ? format(new Date(item.lastActivity), 'MMM d')
    : null;
</script>

<div class="relative bg-card text-card-foreground rounded-lg border shadow-sm">
  <!-- Header with checkbox, name and action button -->
  <div class="flex justify-between items-center p-2 border-b bg-muted/30">
    <div class="flex overflow-hidden gap-2 items-center flex-1 min-w-0">
      <Checkbox.Root
        {checked}
        onCheckedChange={() => (checked = !checked)}
        class="size-4 shrink-0"
      />
      {#if pipeline?.module === 'accounts'}
        <CrmAvatar id={item?.accountId} module="accounts" class="size-6" />
      {:else if pipeline?.module === 'contacts'}
        <CrmAvatar
          id={item?.contactId || item?.candidateId}
          module="contacts"
          class="size-6"
        />
      {:else if pipeline?.module === 'deals'}
        <div
          class="size-6 rounded-full bg-primary/10 flex items-center justify-center"
        >
          <Icon
            icon={pipeline?.module === 'deals'
              ? 'mdi:currency-usd'
              : 'mdi:folder'}
            class="size-4 text-primary"
          />
        </div>
      {:else}
        <div class="truncate">
          <div class="font-medium truncate">{details.name}</div>
          {#if details.subtitle}
            <div class="text-xs text-muted-foreground truncate">
              {details.subtitle}
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <div class="flex items-center gap-1">
      <PipelineItemMenu {item} {pipeline} />
    </div>
  </div>

  <!-- Card Content -->
  <div class="p-3 space-y-2">
    {#if details.amount || details.company || details.probability}
      <div class="flex flex-wrap gap-2 text-xs">
        {#if details.amount}
          <span
            class="inline-flex items-center gap-1 px-2 py-0.5 rounded-full bg-emerald-50 text-emerald-700"
          >
            <Icon icon="mdi:currency-usd" class="size-3" />
            {details.amount}
          </span>
        {/if}
        {#if details.company}
          <span
            class="inline-flex items-center gap-1 px-2 py-0.5 rounded-full bg-blue-50 text-blue-700"
          >
            <Icon icon="mdi:office-building" class="size-3" />
            {details.company}
          </span>
        {/if}
        {#if details.probability}
          <span
            class="inline-flex items-center gap-1 px-2 py-0.5 rounded-full bg-purple-50 text-purple-700"
          >
            <Icon icon="mdi:percent" class="size-3" />
            {details.probability}
          </span>
        {/if}
      </div>
    {/if}

    {#if details.email || details.phone || details.website || details.location}
      <div class="space-y-1 text-xs text-muted-foreground">
        {#if details.email}
          <div class="flex items-center gap-1">
            <Icon icon="mdi:email" class="size-3" />
            <span class="truncate">{details.email}</span>
          </div>
        {/if}
        {#if details.phone}
          <div class="flex items-center gap-1">
            <Icon icon="mdi:phone" class="size-3" />
            <span>{details.phone}</span>
          </div>
        {/if}
        {#if details.website}
          <div class="flex items-center gap-1">
            <Icon icon="mdi:web" class="size-3" />
            <span class="truncate">{details.website}</span>
          </div>
        {/if}
        {#if details.location}
          <div class="flex items-center gap-1">
            <Icon icon="mdi:map-marker" class="size-3" />
            <span class="truncate">{details.location}</span>
          </div>
        {/if}
      </div>
    {/if}

    {#if item?.description}
      <div class="text-sm text-muted-foreground line-clamp-2">
        {item?.description}
      </div>
    {/if}

    <!-- Custom Fields Section -->
    <CustomFields
      editMode={false}
      designMode={false}
      customFields={item?.customFields || {}}
      fieldDefinitions={pipeline?.customFields?.fieldDefinitions || []}
    />
    <div class="space-y-2 mt-2">
      {#if pipeline?.customFields?.length > 0}
        {#each pipeline.customFields as field}
          <div class="flex items-center gap-2 text-xs">
            <div class="flex-1">
              <CustomFieldValue
                {field}
                value={getCustomFieldValue(field.id)}
                onChange={value => updateFieldValue(field.id, value)}
              />
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>

  <!-- Footer -->
  <div
    class="px-2 flex justify-between items-center text-xs border-t bg-muted text-muted-foreground"
  >
    <div class="flex gap-3 items-center">
      <Button
        variant="link"
        size="sm"
        class="text-muted-foreground text-xs"
        on:click={() => {
          popupStore.openPopup('crmObject', {
            module: 'pipelineItems',
            id: item.id,
          });
        }}
      >
        Edit
      </Button>
      <!-- Tasks -->
      {#if tasksCount > 0}
        <div class="flex gap-1 items-center">
          <Icon icon="mdi:checkbox-outline" class="size-3.5" />
          <span>{completedTasksCount}/{tasksCount}</span>
        </div>
      {/if}

      <!-- Notes -->
      {#if notesCount > 0}
        <div class="flex gap-1 items-center">
          <Icon icon="mdi:note-outline" class="size-3.5" />
          <span>{notesCount}</span>
        </div>
      {/if}

      <!-- Comments -->
      {#if commentsCount > 0}
        <Button
          variant="link"
          size="sm"
          class="text-muted-foreground text-xs space-x-1"
          on:click={() => {
            popupStore.openPopup('crmObject', {
              module: 'pipelineItems',
              id: item.id,
            });
          }}
          ><Icon icon="mdi:comment-outline" class="size-3.5" />
          <span>{commentsCount}</span>
        </Button>
      {/if}
    </div>

    <div class="flex items-center gap-2">
      {#if lastActivity}
        <div class="flex gap-1 items-center">
          <Icon icon="mdi:clock-outline" class="size-3.5" />
          <span>{lastActivity}</span>
        </div>
      {/if}
      <div class="flex gap-1 items-center">
        <Icon icon="mdi:clock-outline" class="size-3.5" />
        <span>{daysInStatus}</span>
      </div>
    </div>
  </div>
</div>

<style>
  /* Optional: Remove hover states for the footer items */
  .flex.items-center.gap-1 {
    cursor: pointer;
  }
</style>
