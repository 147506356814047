// lib/api/supabase-realtime/mutation.ts
import { createMutation, useQueryClient } from '@tanstack/svelte-query';
import { supabase } from '../supabase';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';

export function createMutations<T extends { id: string | number }>(
  table: string,
  queryKey: string[],
) {
  const queryClient = useQueryClient();

  const addMutation = createMutation({
    mutationFn: async (newItem: Omit<T, 'id'>) => {
      const { data, error } = await supabase(get(authStore)?.token)
        .from(table)
        .insert(newItem)
        .select()
        .single();

      if (error) throw error;
      return data as T;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const updateMutation = createMutation({
    mutationFn: async ({ id, ...updates }: Partial<T> & { id: T['id'] }) => {
      const { data, error } = await supabase(get(authStore)?.token)
        .from(table)
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return data as T;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const bulkUpdateMutation = createMutation({
    mutationFn: async ({
      ids,
      updates,
    }: {
      ids: T['id'][];
      updates: Partial<T>;
    }) => {
      const { data, error } = await supabase(get(authStore)?.token)
        .from(table)
        .update(updates)
        .in('id', ids)
        .select();

      if (error) throw error;
      return data as T[];
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const deleteMutation = createMutation({
    mutationFn: async (id: T['id']) => {
      const { error } = await supabase(get(authStore)?.token)
        .from(table)
        .delete()
        .eq('id', id);

      if (error) throw error;
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const deleteMultipleMutation = createMutation({
    mutationFn: async (ids: T['id'][]) => {
      const { error } = await supabase(get(authStore)?.token)
        .from(table)
        .delete()
        .in('id', ids);

      if (error) throw error;
      return ids;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    addMutation,
    updateMutation,
    bulkUpdateMutation,
    deleteMutation,
    deleteMultipleMutation,
  };
}
