<script lang="ts">
  import * as Input from '$lib/components/ui/input';
  import * as Select from '$lib/components/ui/select';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import type { CustomField } from '$lib/types/pipeline';

  export let field: Partial<CustomField>;
  export let onUpdate: (field: Partial<CustomField>) => void;
  export let onDelete: () => void;
  export let onDuplicate: () => void;

  let localField = { ...field };

  // Specific settings for each type
  const typeSettings = {
    date: {
      dateFormat: ['Full date', 'Short date', 'Relative'],
      timeFormat: ['12 hour', '24 hour', 'None'],
    },
    number: {
      format: ['Number', 'Decimal', 'Percent'],
      precision: [0, 1, 2, 3, 4],
    },
    currency: {
      currency: ['USD', 'EUR', 'RUB'],
      locale: ['en-US', 'ru-RU', 'de-DE'],
    },
  };

  function updateField() {
    onUpdate(localField);
  }
</script>

<div class="space-y-4 p-4">
  <!-- Field name -->
  <div class="space-y-2">
    <Input.Input
      type="text"
      placeholder="Field name"
      bind:value={localField.label}
      on:change={updateField}
    />
  </div>

  <!-- Type specific settings -->
  {#if localField.type === 'date'}
    <div class="space-y-2">
      <Select.Root
        value={localField.dateFormat || 'Full date'}
        onValueChange={value => {
          localField.dateFormat = value;
          updateField();
        }}
      >
        <Select.Trigger class="w-full">
          <Select.Value placeholder="Date format" />
        </Select.Trigger>
        <Select.Content>
          {#each typeSettings.date.dateFormat as format}
            <Select.Item value={format}>{format}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>

      <Select.Root
        value={localField.timeFormat || '12 hour'}
        onValueChange={value => {
          localField.timeFormat = value;
          updateField();
        }}
      >
        <Select.Trigger class="w-full">
          <Select.Value placeholder="Time format" />
        </Select.Trigger>
        <Select.Content>
          {#each typeSettings.date.timeFormat as format}
            <Select.Item value={format}>{format}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>
    </div>
  {:else if localField.type === 'number'}
    <div class="space-y-2">
      <Select.Root
        value={localField.format || 'Number'}
        onValueChange={value => {
          localField.format = value;
          updateField();
        }}
      >
        <Select.Trigger class="w-full">
          <Select.Value placeholder="Number format" />
        </Select.Trigger>
        <Select.Content>
          {#each typeSettings.number.format as format}
            <Select.Item value={format}>{format}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>

      <Select.Root
        value={localField.precision?.toString() || '0'}
        onValueChange={value => {
          localField.precision = parseInt(value);
          updateField();
        }}
      >
        <Select.Trigger class="w-full">
          <Select.Value placeholder="Decimal places" />
        </Select.Trigger>
        <Select.Content>
          {#each typeSettings.number.precision as precision}
            <Select.Item value={precision.toString()}>{precision}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>
    </div>
  {:else if localField.type === 'currency'}
    <div class="space-y-2">
      <Select.Root
        value={localField.currency || 'USD'}
        onValueChange={value => {
          localField.currency = value;
          updateField();
        }}
      >
        <Select.Trigger class="w-full">
          <Select.Value placeholder="Currency" />
        </Select.Trigger>
        <Select.Content>
          {#each typeSettings.currency.currency as currency}
            <Select.Item value={currency}>{currency}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>

      <Select.Root
        value={localField.locale || 'en-US'}
        onValueChange={value => {
          localField.locale = value;
          updateField();
        }}
      >
        <Select.Trigger class="w-full">
          <Select.Value placeholder="Format locale" />
        </Select.Trigger>
        <Select.Content>
          {#each typeSettings.currency.locale as locale}
            <Select.Item value={locale}>{locale}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>
    </div>
  {/if}

  <!-- Actions -->
  <div class="flex gap-2 pt-4 border-t">
    <Button variant="ghost" class="flex-1" on:click={onDuplicate}>
      <Icon icon="mdi:content-copy" class="mr-2 size-4" />
      Duplicate
    </Button>
    <Button
      variant="ghost"
      class="flex-1 hover:border-destructive "
      on:click={onDelete}
    >
      <Icon icon="mdi:delete" class="mr-2 size-4" />
      Delete
    </Button>
  </div>
</div>
