import { writable, get } from 'svelte/store';
import type { Pipeline } from '../types/pipeline';
import {
  getPipelines,
  savePipeline,
  deletePipeline as apiDeletePipeline,
} from '../api/pipeline-api';
import { appStore } from './app-store';

function createPipelineStore() {
  const { subscribe, set, update } = writable<Pipeline[]>([]);

  async function initialize() {
    const pipelines = await getPipelines();
    if (pipelines) {
      set(pipelines);
    }
  }

  initialize();

  return {
    subscribe,

    // Create new pipeline
    addPipeline: async (pipeline: Pipeline) => {
      const newPipeline = await savePipeline(pipeline);
      update(pipelines => [...pipelines, newPipeline]);

      // Update app store and navigate
      appStore.select({ id: newPipeline.id, module: 'pipelines' });
      appStore.openPopup(true);

      return newPipeline;
    },

    // Read all pipelines
    loadPipelines: async () => {
      const pipelines = await getPipelines();
      if (pipelines) {
        set(pipelines);
      }
      return pipelines;
    },

    // Update existing pipeline
    updatePipeline: async (pipeline: Pipeline) => {
      const updatedPipeline = await savePipeline(pipeline);
      update(pipelines =>
        pipelines.map(p => (p.id === updatedPipeline.id ? updatedPipeline : p)),
      );
      return get({ subscribe }).find(p => p.id === updatedPipeline.id);
    },

    // Delete pipeline
    deletePipeline: async (id: string) => {
      await apiDeletePipeline(id);
      update(pipelines => pipelines.filter(p => p.id !== id));
    },

    // Load template pipelines
    loadTemplates: (templates: Pipeline[]) => set(templates),

    // Get pipeline by ID
    getPipelineById: (id: string) => get({ subscribe }).find(p => p.id === id),
  };
}

export const pipelineStore = createPipelineStore();
