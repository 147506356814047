import { writable } from 'svelte/store';
import type { Filter, SortConfig } from '.';

export function createUrlState() {
  // Парсим начальное состояние из URL
  const searchParams = new URLSearchParams(window.location.search);

  const initialFilters = searchParams.get('filters')
    ? JSON.parse(decodeURIComponent(searchParams.get('filters') || '[]'))
    : [];

  const initialSort = searchParams.get('sort')
    ? JSON.parse(decodeURIComponent(searchParams.get('sort') || '{}'))
    : null;

  // Создаем store
  const filters = writable<Filter[]>(initialFilters);
  const sort = writable<SortConfig | null>(initialSort);

  // Обновляем URL при изменении состояния
  filters.subscribe(value => {
    const params = new URLSearchParams(window.location.search);
    params.set('filters', encodeURIComponent(JSON.stringify(value)));
    window.history.replaceState({}, '', `?${params.toString()}`);
  });

  sort.subscribe(value => {
    const params = new URLSearchParams(window.location.search);
    if (value) {
      params.set('sort', encodeURIComponent(JSON.stringify(value)));
    } else {
      params.delete('sort');
    }
    window.history.replaceState({}, '', `?${params.toString()}`);
  });

  return { filters, sort };
}
