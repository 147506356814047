<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { Textarea } from '$lib/components/ui/textarea';
  import Comment from './comment.svelte';
  import { authStore } from '$lib/stores/auth-store';
  import { supabase } from '$lib/api/supabase';
  import { get } from 'svelte/store';

  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils';
  import { toast } from 'svelte-sonner';

  export let itemId: string | undefined = undefined;
  export let module: string | undefined = undefined;

  let newComment = '';
  let comments: Array<{
    id: string;
    content: string;
    createdAt: string;
    user: string;
  }> = [];
  let isLoading = false;

  $: user = authStore.getName();

  async function loadComments() {
    if (!itemId) return;

    const { data: item, error } = await supabase(get(authStore)?.token)
      .from('pipelineItems')
      .select('data')
      .eq('id', itemId)
      .single();

    if (error) {
      toast.error('Failed to load comments');
      return;
    }

    comments = item?.data?.comments || [];
  }

  async function saveComments(updatedComments: any[]) {
    if (!itemId) return false;

    const { error } = await supabase(get(authStore)?.token)
      .from('pipelineItems')
      .update({
        data: {
          comments: updatedComments,
        },
      })
      .eq('id', itemId);

    if (error) {
      toast.error('Failed to save comment');
      return false;
    }
    return true;
  }

  async function handleSubmit() {
    if (!newComment.trim() || !user) return;

    isLoading = true;
    const comment = {
      id: crypto.randomUUID(),
      content: newComment,
      createdAt: new Date().toISOString(),
      user,
    };

    const success = await saveComments([...comments, comment]);
    if (success) {
      comments = [...comments, comment];
      newComment = '';
    }
    isLoading = false;
  }

  async function handleDelete(commentId: string) {
    const updatedComments = comments.filter(c => c.id !== commentId);
    const success = await saveComments(updatedComments);
    if (success) {
      comments = updatedComments;
    }
  }

  async function handleEdit(commentId: string, newContent: string) {
    const updatedComments = comments.map(c =>
      c.id === commentId ? { ...c, content: newContent } : c,
    );
    const success = await saveComments(updatedComments);
    if (success) {
      comments = updatedComments;
    }
  }

  // Load comments when component mounts
  $: if (itemId) {
    loadComments();
  }
</script>

<div class="flex flex-col gap-6">
  <!-- Comments List -->
  <div class="flex flex-col gap-4">
    {#each comments as comment (comment.id)}
      <Comment
        username={comment.user}
        content={comment.content}
        createdAt={comment.createdAt}
        currentUserId={user}
        onDelete={() => handleDelete(comment.id)}
        onEdit={content => handleEdit(comment.id, content)}
      />
    {/each}
  </div>

  <!-- New Comment Input -->
  <div class="flex flex-col gap-2">
    <Textarea
      bind:value={newComment}
      placeholder="Write a comment..."
      rows="3"
      class={cn('resize-none', newComment && 'focus:ring-2 focus:ring-primary')}
    />
    <div class="flex justify-end">
      <Button
        variant="default"
        size="sm"
        disabled={!newComment.trim() || isLoading}
        on:click={handleSubmit}
      >
        {#if isLoading}
          <Icon icon="mdi:loading" class="mr-2 h-4 w-4 animate-spin" />
        {/if}
        Comment
      </Button>
    </div>
  </div>
</div>
