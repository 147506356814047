<script lang="ts">
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import { newItemStore } from '$lib/stores/new-item-store';

  import { createQuery } from '@tanstack/svelte-query';
  import { getAccountInvoices } from '$lib/api/invoice-api';
  import InvoiceStatus from '$lib/components/hanta/invoice-status.svelte';

  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import { format } from 'date-fns';
  import { formatCurrency } from '$lib/utils/hanta-utils';

  export let accountId: string;

  const invoicesQuery = createQuery({
    queryKey: ['accountInvoices', accountId],
    queryFn: () => getAccountInvoices(accountId),
    enabled: !!accountId,
  });

  $: ({ data: invoices, isLoading, isError, error } = $invoicesQuery);

  function createNewItem() {
    if ($appStore.currentModule) {
      newItemStore.createItem('invoices');
    }
    newItemStore.openDialog();
  }
</script>

<Button
  on:click={createNewItem}
  variant="outline"
  data-testid="nav-new-item"
  class="my-2 hidden"
>
  <Icon icon="mdi:plus" class="size-4 text-primary" aria-hidden="true" />
  <span class="ml-2">Create Invoice</span>
  <span class="ml-auto text-xs text-muted-foreground hidden">⌘K</span>
</Button>

{#if isLoading}
  <p>Loading invoices...</p>
{:else if isError}
  <p>Error loading invoices: {error.message}</p>
{:else if invoices && invoices.length > 0}
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Actions</TableHead>
        <TableHead>Invoice Number</TableHead>
        <TableHead>Date</TableHead>
        <TableHead>Status</TableHead>
        <TableHead class="text-right">Amount</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {#each invoices as invoice}
        <TableRow>
          <TableCell>
            <Button
              variant="outline"
              size="sm"
              on:click={() => {
                appStore.select({
                  id: invoice.id,
                  module: 'invoices',
                });
                appStore.openPopup(true);
              }}
            >
              View
            </Button>
          </TableCell>
          <TableCell>{invoice.invoiceId}</TableCell>
          <TableCell
            >{format(new Date(invoice.createdAt), 'dd MMM yyyy')}</TableCell
          >
          <TableCell>
            <InvoiceStatus status={invoice.status} />
          </TableCell>
          <TableCell class="text-right"
            >{formatCurrency(invoice.gross)}</TableCell
          >
        </TableRow>
      {/each}
    </TableBody>
  </Table>
{:else}
  <p>No invoices found for this account.</p>
{/if}
