<script lang="ts">
  import * as Select from '$lib/components/ui/select/index.js';
  import Icon from '@iconify/svelte';
  import {
    type DateValue,
    DateFormatter,
    getLocalTimeZone,
    today,
    fromDate,
    toCalendarDate,
  } from '@internationalized/date';
  import { cn } from '$lib/utils/hanta-utils.js';
  import { buttonVariants } from '$lib/components/ui/button/index.js';
  import { Calendar } from '$lib/components/ui/calendar/index.js';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import * as Form from '$lib/components/ui/form/index.js';
  import { stringProxy } from 'sveltekit-superforms';
  import HantaInputMultiSelectLean from './hanta-input-multi-select-lean.svelte';
  import Input from '$lib/components/hanta/input.svelte';

  export let form;
  export let name;
  export let label;
  export let description = '';
  export let withTime = false;
  export let readOnly = false;
  export let type: 'htmlDate' | 'hanta' = 'hanta';
  export let withOffset = false;
  export let required = false;

  const timeOptions = [];
  const { form: formData, enhance } = form;
  const df = new DateFormatter('en-US', {
    dateStyle: 'long',
  });

  let value: DateValue | undefined;
  let timeValue: string;
  let placeholder: DateValue = today(getLocalTimeZone());

  export let offsets = [
    { value: 0, label: 'Today' },
    { value: 1, label: 'Tomorrow' },
    { value: 3, label: 'In 3 days' },
    { value: 7, label: 'In a week' },
  ];

  $: proxy = stringProxy(formData, name, {
    empty: 'undefined',
  });

  $: if ($proxy) {
    try {
      const localDateTime = new Date($proxy);
      value = toCalendarDate(fromDate(localDateTime, getLocalTimeZone()));

      if (withTime) {
        try {
          const split = localDateTime.toLocaleTimeString().split(':');
          let timeString = split[0] + ':' + split[1];
          timeValue = timeOptions.find(time => time === timeString);
        } catch (error) {
          console.error('Error processing time:', error);
          timeValue = undefined;
        }
      }
    } catch (error) {
      console.error('Error parsing date:', error);
      value = undefined;
    }
  } else {
    value = undefined;
  }

  function convertToIsoString(dateVal: DateValue) {
    const localDateTime = new Date($proxy);
    const date = dateVal.toDate(getLocalTimeZone());

    localDateTime.setFullYear(date.getFullYear());
    localDateTime.setMonth(date.getMonth());
    localDateTime.setDate(date.getDate());

    return localDateTime.toISOString();
  }

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      let hours = i < 10 ? '0' + i : i;
      let minutes = j < 10 ? '0' + j : j;
      timeOptions.push(`${hours}:${minutes}`);
    }
  }

  const changeTime = timeString => {
    if (!timeString || timeString === 'undefined') {
      if ($proxy) {
        // Keep the date but set time to midnight
        const localDateTime = new Date($proxy);
        localDateTime.setHours(0);
        localDateTime.setMinutes(0);
        localDateTime.setSeconds(0);
        localDateTime.setMilliseconds(0);
        $proxy = localDateTime.toISOString();
      } else {
        $proxy = undefined;
      }
      timeValue = '';
      return;
    }

    if (!$proxy) {
      // If no date is selected, use today's date
      $proxy = today(getLocalTimeZone())
        .toDate(getLocalTimeZone())
        .toISOString();
    }

    const localDateTime = new Date($proxy);
    const [hours, minutes] = timeString.split(':').map(Number);
    localDateTime.setHours(hours);
    localDateTime.setMinutes(minutes);
    localDateTime.setSeconds(0);
    localDateTime.setMilliseconds(0);
    $proxy = localDateTime.toISOString();
  };

  function findClosestTime() {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    let closestTime = timeOptions[0];
    let minDiff = Infinity;

    for (const time of timeOptions) {
      const [hours, minutes] = time.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes;
      const diff = Math.abs(totalMinutes - currentTotalMinutes);

      if (diff < minDiff) {
        minDiff = diff;
        closestTime = time;
      }
    }

    return closestTime;
  }

  function handleTimeDropdownOpen() {
    if (!timeValue || timeValue === '' || timeValue === '00:00') {
      const closest = findClosestTime();
      timeValue = closest;

      if (!$proxy) {
        // If no date is selected, use today's date
        $proxy = today(getLocalTimeZone())
          .toDate(getLocalTimeZone())
          .toISOString();
      }

      changeTime(closest);
    }
  }

  $: if (withTime && !timeValue) {
    timeValue = '';
  }
</script>

<Form.Field class="flex flex-col" {form} {name} {...$$restProps}>
  <Form.Control let:attrs>
    <div class="flex flex-row gap-2 w-full">
      <div class="flex flex-col gap-2 w-full">
        {#if label}
          <Form.Label class="flex flex-row gap-2 items-center" {required}>
            <slot name="icon" />
            {label}
          </Form.Label>
        {/if}

        {#if !readOnly}
          {#if type === 'hanta'}
            <Popover.Root>
              <Popover.Trigger
                {...attrs}
                class={cn(
                  buttonVariants({ variant: 'outline' }),
                  'min-w-[180px] justify-start pl-4 text-left font-normal',
                  !value && 'text-muted-foreground',
                )}
              >
                {value
                  ? df.format(value.toDate(getLocalTimeZone()))
                  : 'Pick a date'}
                <Icon icon="mdi:calendar" class="ml-auto w-4 h-4 opacity-50" />
              </Popover.Trigger>
              <Popover.Content class="p-0 w-auto" side="top">
                {#if withOffset}
                  <div class="flex items-center px-8 py-4 space-x-2">
                    <Form.Label>in</Form.Label>
                    <Select.Root
                      items={offsets}
                      onSelectedChange={v => {
                        if (!v) return;
                        $proxy = convertToIsoString(
                          today(getLocalTimeZone()).add({ days: v.value }),
                        );
                      }}
                    >
                      <Select.Trigger>
                        <Select.Value placeholder="Select" />
                      </Select.Trigger>
                      <Select.Content>
                        {#each offsets as item}
                          <Select.Item value={item.value}
                            >{item.label}</Select.Item
                          >
                        {/each}
                      </Select.Content>
                    </Select.Root>
                  </div>
                {/if}
                <Calendar
                  bind:placeholder
                  calendarLabel={label}
                  initialFocus
                  onValueChange={v => {
                    if (v) {
                      $proxy = convertToIsoString(v);
                    } else {
                      $proxy = '';
                    }
                  }}
                  {value}
                />
              </Popover.Content>
            </Popover.Root>
          {:else}
            <Input type="date" {...attrs} bind:value class="w-full" />
          {/if}
        {:else if value}
          <span class="text-sm font-semibold text-foreground text-nowrap"
            >{df.format(value.toDate(getLocalTimeZone()))}</span
          >
        {/if}
      </div>
      {#if withTime}
        {#if !readOnly}
          <div class="flex flex-col gap-2">
            <Form.Label>Time</Form.Label>
            <div class="w-28 shrink-0 grow-0">
              <HantaInputMultiSelectLean
                {readOnly}
                simpleMode={true}
                placeholder="00:00"
                multiple={false}
                onSelectChanged={val => {
                  changeTime(val);
                }}
                onDropdownOpen={handleTimeDropdownOpen}
                value={timeValue}
                options={timeOptions.map(time => time)}
              />
            </div>
          </div>
        {:else}
          {timeValue}
        {/if}
      {/if}
    </div>
    <Form.Description>
      {description ?? ''}
    </Form.Description>
    <Form.FieldErrors />
    <input hidden name={attrs.name} value={$proxy} />
  </Form.Control>
</Form.Field>
