<script lang="ts">
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import {
    leadWithShipmentAndRateSchema,
    type LeadWithShipmentAndRate,
  } from '$db/schema';
  import { saveLead, saveOrder } from '$lib/api/mutations';
  import { appStore } from '$lib/stores/app-store';
  import { createMutation } from '@tanstack/svelte-query';
  import { alertStore } from '$lib/stores/alert-store';

  import LeadInfoCard from '../leads_orders/lead-order-info-card.svelte';
  import CustomerInfoCard from '../leads_orders/customer-info-card.svelte';
  import ShippingDetailsCard from '../leads_orders/shipping-details-card.svelte';
  import WeightVolumeCard from '../leads_orders/weight-volume-card.svelte';
  import AdditionalServicesCard from '../leads_orders/additional-services-card.svelte';
  import FinancialDetailsCard from '../leads_orders/financial-details-card.svelte';
  import RateDetailsCard from '../leads_orders/rate-details-card.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  export let item;

  let billedWeight = 0;

  const formSchema = leadWithShipmentAndRateSchema.partial();
  const mutation = createMutation({
    mutationFn: (data: LeadWithShipmentAndRate) => saveLead(data),
    onSuccess: () => {
      appStore.closeActivity();
      $appStore.queryClient.invalidateQueries(['leads']);
    },
  });

  const form = superForm<LeadWithShipmentAndRate>(item, {
    resetForm: false,
    SPA: true,
    validators: zodClient(formSchema),
    validationMethod: 'oninput',
    dataType: 'json',
    onSubmit: async () => {
      console.debug('Form data', $formData);
      if (Object.keys($errors).length > 0) {
        alertStore.show(
          'Validation Error',
          'Please check the form for errors',
          undefined,
          () => {
            // Optional: Add form resubmission logic
          },
        );
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      $formData.shipment.billedWeight = billedWeight;

      $mutation.mutate($formData, {
        onError(error) {
          console.error('Error', error);
          alertStore.show(
            'Error Saving Lead',
            error.message || 'An error occurred while saving the lead',
            undefined,
            () => {
              // Optional: Add retry logic
              $mutation.mutate($formData);
            },
          );
        },
        onSuccess: () => {
          $appStore.queryClient.invalidateQueries(['leads', $formData.id], {
            exact: true,
            refetchActive: true,
          });
        },
      });
    },
  });

  let { form: formData, enhance, errors } = form;

  $: readOnly = $formData.status !== 'NEW';

  const createOrder = async (awbNumber: string) => {
    try {
      const newOrder = await saveOrder({
        leadId: $formData.id,
        status: 'DRAFT',
        name: awbNumber,
        account: $formData.account,
        contactObj: $formData.contactObj,
        user: $formData.user,
        orderDate: new Date().toISOString(),
      });

      $formData.status = 'Converted to Order';
      $formData.shipment.orderId = newOrder.id;
      form.submit();
      setTimeout(() => {
        appStore.select({
          module: 'orders',
          id: newOrder.id,
        });
        appStore.openPopup(true);
      }, 500);
    } catch (error) {
      alertStore.show(
        'Error Creating Order',
        error.message || 'Failed to create order from lead',
        undefined,
        () => {
          // Optional: Add retry logic
          createOrder(awbNumber);
        },
      );
    }
  };
</script>

<main class="overflow-scroll p-6 py-24 min-h-screen bg-cool">
  <form class="space-y-6 w-full" method="POST" use:enhance>
    <div class="mx-auto max-w-5xl">
      <div class="grid grid-cols-1 gap-8 xl:grid-cols-2">
        <LeadInfoCard {form} {readOnly} {createOrder} />

        <CustomerInfoCard {form} {readOnly} />
        <ShippingDetailsCard {form} {readOnly} />
        <WeightVolumeCard {form} {readOnly} bind:billedWeight />
        <AdditionalServicesCard {form} {readOnly} />
        <FinancialDetailsCard {form} {readOnly} {billedWeight} />
      </div>

      <RateDetailsCard rate={$formData.shipment?.rate} />

      <FormActions {form} />
    </div>
  </form>
</main>
