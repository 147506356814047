import type { Filter } from '.';

/**
 * Создает фильтр для поиска по тексту
 */
export function createSearchFilter(
  term: string,
  field: string = 'title',
): Filter | null {
  if (!term || !term.trim()) return null;

  return {
    field,
    operator: 'ilike',
    value: `%${term.trim()}%`,
  };
}

/**
 * Создает фильтр для приоритета
 */
export function createPriorityFilter(priority: number | null): Filter | null {
  if (priority === null || priority === undefined) return null;

  return {
    field: 'priority',
    operator: 'eq',
    value: priority,
  };
}

/**
 * Создает фильтр для статуса завершения
 */
export function createCompletedFilter(
  completed: boolean | null,
): Filter | null {
  if (completed === null) return null;

  return {
    field: 'completed',
    operator: 'eq',
    value: completed,
  };
}

/**
 * Создает фильтр для диапазона дат
 */
export function createDateRangeFilter(
  field: string,
  startDate: Date | null,
  endDate: Date | null,
): Filter | null {
  if (!startDate && !endDate) return null;

  if (startDate && endDate) {
    return {
      field,
      operator: 'range',
      value: [startDate.toISOString(), endDate.toISOString()],
    };
  }

  if (startDate) {
    return {
      field,
      operator: 'gte',
      value: startDate.toISOString(),
    };
  }

  if (endDate) {
    return {
      field,
      operator: 'lte',
      value: endDate.toISOString(),
    };
  }

  return null;
}

/**
 * Объединяет массив фильтров, удаляя null значения
 */
export function combineFilters(filters: (Filter | null)[]): Filter[] {
  return filters.filter((f): f is Filter => f !== null);
}

/**
 * Создает сложный фильтр на основе нескольких параметров
 */
export function createComplexFilter({
  searchTerm,
  priority,
  completed,
  startDate,
  endDate,
}: {
  searchTerm?: string;
  priority?: number | null;
  completed?: boolean | null;
  startDate?: Date | null;
  endDate?: Date | null;
}): Filter[] {
  const filters = [
    createSearchFilter(searchTerm || ''),
    createPriorityFilter(priority),
    createCompletedFilter(completed),
    createDateRangeFilter('due_date', startDate, endDate),
  ];

  return combineFilters(filters);
}

/**
 * Сериализует фильтры для URL
 */
export function serializeFilters(filters: Filter[]): string {
  return encodeURIComponent(JSON.stringify(filters));
}

/**
 * Десериализует фильтры из URL
 */
export function deserializeFilters(filterString: string | null): Filter[] {
  if (!filterString) return [];

  try {
    const filters = JSON.parse(decodeURIComponent(filterString));
    return Array.isArray(filters) ? filters : [];
  } catch {
    return [];
  }
}
