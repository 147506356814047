<script lang="ts">
  import { appStore } from '$lib/stores/app-store';
  import { cn } from '$lib/utils/hanta-utils';
  import Dashboard from '$lib/pages/dashboard/dashboard.svelte';
  import Timelogs from '$lib/pages/timelogs/timelogs.svelte';
  import InvoicesOverall from '../pages/invoices/overall/invoices-overall.svelte';

  import TasksCard from '$lib/pages/tasks/tasks-card.svelte';
  import Pipelines from '$lib/pages/pipelines/pipelines.svelte';
  import AnalyticsPage from '$lib/pages/analytics/analytics-page.svelte';
  import SettingsPage from '$lib/pages/settings/settings-page.svelte';
  export let module: string;
</script>

<div class={cn($appStore.isCollapsed ? 'ml-8' : 'ml-[170px]')}>
  {#if module === 'timelogs'}
    <div
      class={cn(
        $appStore.isMobile ? 'max-w-md' : 'w-full fixed pl-16 pt-14 h-1/2',
      )}
    >
      <Timelogs />
    </div>
  {:else if module === 'analytics'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <AnalyticsPage />
    </div>
  {:else if module === 'dashboard'}
    <div
      class={cn($appStore.isMobile ? 'max-w-md' : 'fixed pl-16 pt-14 h-1/2')}
    >
      <Dashboard />
    </div>
  {:else if module === 'finance'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <InvoicesOverall />
    </div>
  {:else if module === 'crm'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <InvoicesOverall />
    </div>
  {:else if module === 'pipelines'}
    <Pipelines />
  {:else if module === 'todos'}
    <div class="p-12">
      <TasksCard class="w-full min-h-96" />
    </div>
  {:else if module === 'settings'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <SettingsPage />
    </div>
  {:else if module === 'invoices'}
    <div
      class={cn(
        $appStore.isMobile
          ? 'max-w-md'
          : 'flex w-full justify-center p-12 min-h-screen',
      )}
    >
      <InvoicesOverall />
    </div>
  {/if}
</div>
