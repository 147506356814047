<script lang="ts">
  import * as Card from '$lib/components/ui/card';
  import { Input } from '$lib/components/ui/input';
  import { Textarea } from '$lib/components/ui/textarea';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import ItemSelectionDialog from './item-selection-dialog.svelte';
  import type { Phase } from '$lib/types/pipeline';
  import { writable } from 'svelte/store';

  export let phase: Phase;
  export let isPreviewMode = false;
  export let module: string;
  export let onRemove: (id: string) => void;
  export let onAddItem: (item: any) => void;
  export let onRemoveItem: (phaseId: string, itemId: string) => void;

  let showItemDialog = false;
  const isLoading = writable(false);

  async function handleAddItem(item: any) {
    try {
      $isLoading = true;
      await onAddItem(item);
    } finally {
      $isLoading = false;
      showItemDialog = false;
    }
  }

  async function handleRemoveItem(phaseId: string, itemId: string) {
    try {
      $isLoading = true;
      await onRemoveItem(phaseId, itemId);
    } finally {
      $isLoading = false;
    }
  }
</script>

<ItemSelectionDialog
  bind:showDialog={showItemDialog}
  {module}
  onSelect={handleAddItem}
  onClose={() => (showItemDialog = false)}
/>

<Card.Root class="w-40 relative border">
  <Card.Content class="p-4">
    {#if isPreviewMode}
      <div class="space-y-4">
        <div class="flex justify-between items-center">
          <h3 class="font-medium">{phase.name || 'Untitled Phase'}</h3>
          <Button
            variant="ghost"
            size="sm"
            on:click={() => (showItemDialog = true)}
            disabled={$isLoading}
          >
            <Icon icon="mdi:plus" class="w-4 h-4" />
          </Button>
        </div>

        {#if phase.description}
          <p class="text-sm text-muted-foreground">
            {phase.description}
          </p>
        {/if}

        <!-- Items List -->
        <div class="space-y-2">
          {#each phase.items || [] as item (item.id)}
            <div
              class="flex justify-between items-center p-2 rounded-lg bg-muted"
            >
              <span class="text-sm">{item.name}</span>
              <button
                class="text-destructive"
                on:click={() => handleRemoveItem(phase.id, item.id)}
                disabled={$isLoading}
              >
                <Icon icon="mdi:close" class="w-4 h-4" />
              </button>
            </div>
          {/each}
        </div>
      </div>
    {:else}
      <div class="space-y-4 h-full">
        <slot />
        <div class="flex gap-4 items-center">
          <div class="flex-1">
            <Input bind:value={phase.name} placeholder="Phase name" />
          </div>
        </div>
        <Textarea
          bind:value={phase.description}
          placeholder="Phase description (optional)"
          rows={2}
        />
      </div>
    {/if}
  </Card.Content>
</Card.Root>
