<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { cn } from '$lib/utils/hanta-utils';
  import { getFavorites } from '$lib/api/queries';
  import type { Favorite } from '$db/schema';
  import Button from '$lib/components/ui/button/button.svelte';
  import { appStore } from '$lib/stores/app-store';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import Icon from '@iconify/svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { getAllRoutes } from '$lib/config/routes';

  const favoritesQuery = createQuery({
    queryKey: ['favorites'],
    queryFn: () => getFavorites(),
  });

  function getIcon(type: string) {
    const module = type.toLowerCase() + 's';
    return getAllRoutes().find(route => route.module === module)?.icon;
  }

  function getEntityType(favorite: Favorite) {
    const entityMap = {
      invoiceId: 'Invoice',
      accountId: 'Account',
      contactId: 'Contact',
      dealId: 'Deal',
      leadId: 'Lead',
      orderId: 'Order',
      issueId: 'Issue',
    };

    const idKey = Object.keys(entityMap).find(
      key => favorite[key as keyof Favorite],
    );
    return idKey ? entityMap[idKey as keyof typeof entityMap] : 'Unknown';
  }
</script>

{#if $appStore.isCollapsed}
  <div class="my-2">
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        class="flex gap-2 items-center px-2 py-1.5 w-full text-sm rounded-md hover:bg-muted"
      >
        <Icon icon="mdi:star" class="ml-2 text-yellow-500 size-5" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content class="w-72 z-[99999]" side="right" align="start">
        {#if $favoritesQuery.isLoading}
          <DropdownMenu.Item>Loading...</DropdownMenu.Item>
        {:else if $favoritesQuery.isError}
          <DropdownMenu.Item class="text-destructive"
            >Error loading favorites</DropdownMenu.Item
          >
        {:else if $favoritesQuery.data?.length === 0}
          <DropdownMenu.Item class="text-muted-foreground"
            >No favorites yet</DropdownMenu.Item
          >
        {:else}
          {#each $favoritesQuery.data || [] as favorite}
            {@const type = getEntityType(favorite)}
            {@const module = type.toLowerCase() + 's'}
            {@const field = type.toLowerCase() + 'Id'}

            <DropdownMenu.Item
              class={cn(
                $appStore?.selected?.id === favorite[field]
                  ? 'bg-primary text-white'
                  : '',
              )}
            >
              <button
                class={cn('flex items-center w-full space-x-2')}
                on:click={() => {
                  appStore.select({
                    id: favorite[field],
                    module,
                  });
                  appStore.openPopup(true);
                }}
              >
                <CrmAvatar
                  id={favorite[field]}
                  {module}
                  size="size-4 rounded-full!"
                  iconOnly={true}
                  asDiv={true}
                >
                  <Icon icon={getIcon(type)} />
                </CrmAvatar>
                <div class="w-full text-left truncate">
                  {favorite[type.toLowerCase()]['name']}
                </div>
              </button>
            </DropdownMenu.Item>
          {/each}
        {/if}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  </div>
{:else}
  <div class="my-4 space-y-1">
    <div class="flex items-center px-2 py-1.5 text-sm font-semibold">
      <Icon icon="mdi:star" class="ml-2 text-yellow-500 size-5" />
      <span class="ml-2">Favorites</span>
    </div>

    {#if $favoritesQuery.isLoading}
      <div class="px-2 py-1 text-sm text-muted-foreground">Loading...</div>
    {:else if $favoritesQuery.isError}
      <div class="px-2 py-1 text-sm text-destructive">
        Error loading favorites
      </div>
    {:else if $favoritesQuery.data?.length === 0}
      <div class="px-2 py-1 text-sm text-muted-foreground">
        No favorites yet
      </div>
    {:else}
      <nav class="space-y-1">
        {#each $favoritesQuery.data || [] as favorite}
          {@const type = getEntityType(favorite)}
          {@const module = type.toLowerCase() + 's'}
          {@const field = type.toLowerCase() + 'Id'}

          <Button
            variant="ghost"
            class={cn(
              'w-full ',
              $appStore?.selected?.id === favorite[field] ? 'bg-secondary' : '',
            )}
            on:click={() => {
              appStore.select({
                id: favorite[field],
                module,
              });
              appStore.openPopup(true);
            }}
          >
            <CrmAvatar
              id={favorite[field]}
              {module}
              size="size-4 rounded-full!"
              iconOnly={true}
              asDiv={true}
            >
              <Icon icon={getIcon(type)} />
            </CrmAvatar>
            <div class="ml-2 w-full text-left truncate">
              {favorite[type.toLowerCase()]['name']}
            </div>
          </Button>
        {/each}
      </nav>
    {/if}
  </div>
{/if}
