<script lang="ts">
  import Upload from './upload.svelte';
  import ListFiles from './list-files.svelte';
  import { cn } from '$lib/utils/hanta-utils';

  export let title = 'Files';
  export let noFilesFoundLabel = 'No files found';
  export let withPreviewImages = false;

  export let folder;
  export let compact = false;

  export let singleFileMode = false;
  export let singleFileName = '';
  export let uploadOnEnd = false;
  export let maxColumns = 3;

  export let onUpload = () => {};
  export let onRemove = () => {};
  export let onExtractContent = (content: string) => {};
  export let extractContent = false;

  let refresh = 0;
</script>

<div class="relative">
  <div class="text-base">{title ?? ''}</div>

  <div
    class={cn(
      singleFileMode && 'flex items-center space-x-2',
      uploadOnEnd && 'flex flex-col-reverse',
    )}
  >
    <div class="flex-1 hover:bg-secondary">
      <Upload
        bind:refresh
        {folder}
        {compact}
        {singleFileName}
        {onUpload}
        {extractContent}
        {onExtractContent}
      />
    </div>

    <ListFiles
      {folder}
      bind:refresh
      {withPreviewImages}
      {noFilesFoundLabel}
      {singleFileMode}
      {onUpload}
      {onRemove}
      {maxColumns}
    />
  </div>
</div>
