<script lang="ts">
  import { Input } from '$lib/components/ui/input';
  import { Checkbox } from '$lib/components/ui/checkbox';
  import Icon from '@iconify/svelte';
  import type { CustomField } from '$lib/types/pipeline';

  import { cn } from '$lib/utils/hanta-utils';

  export let field: CustomField;
  export let value: any;
  export let isEditable: boolean = false;

  let inputId = crypto.randomUUID();

  function getCustomFieldIcon(type) {
    switch (type) {
      case 'number':
        return 'mdi:numeric';
      case 'currency':
        return 'mdi:currency-usd';
      case 'percentage':
        return 'mdi:percent';
      case 'date':
        return 'mdi:calendar';
      case 'boolean':
        return 'mdi:check-circle';
      case 'text':
        return 'mdi:text';
      case 'url':
        return 'mdi:link';
      case 'email':
        return 'mdi:email';
      case 'phone':
        return 'mdi:phone';
      default:
        return 'mdi:format-text';
    }
  }

  function getCustomFieldColor(type) {
    switch (type) {
      case 'number':
        return 'bg-blue-50 text-blue-700';
      case 'currency':
        return 'bg-emerald-50 text-emerald-700';
      case 'percentage':
        return 'bg-purple-50 text-purple-700';
      case 'date':
        return 'bg-orange-50 text-orange-700';
      case 'boolean':
        return 'bg-gray-50 text-gray-700';
      case 'url':
        return 'bg-indigo-50 text-indigo-700';
      case 'email':
        return 'bg-pink-50 text-pink-700';
      case 'phone':
        return 'bg-cyan-50 text-cyan-700';
      default:
        return 'bg-gray-50 text-gray-700';
    }
  }
</script>

<div class="field-container">
  <label
    class="flex space-x-2 items-center text-md text-muted-foreground"
    for={inputId}
  >
    <Icon
      icon={getCustomFieldIcon(field.type)}
      class={cn(
        getCustomFieldColor(field.type),
        'size-4 text-muted-foreground',
      )}
    />
    <span class="font-medium text-muted-foreground">{field.label}</span>
  </label>
  <div class="input-wrapper">
    {#if isEditable}
      {#if field.type === 'date'}
        <div class="input-group">
          <input
            type="date"
            id={inputId}
            bind:value
            class="input-field date-input"
          />
        </div>
      {:else if field.type === 'boolean'}
        <div class="checkbox-group">
          <Checkbox bind:checked={value} class="custom-checkbox" />
        </div>
      {:else if field.type === 'number' || field.type === 'currency' || field.type === 'percentage'}
        <div class="input-group">
          <Input
            type="number"
            id={inputId}
            bind:value
            class="input-field"
            step={field.precision ? Math.pow(10, -field.precision) : 1}
          />
        </div>
      {:else}
        <div class="input-group">
          <Input
            type={field.type === 'email'
              ? 'email'
              : field.type === 'url'
                ? 'url'
                : 'text'}
            id={inputId}
            bind:value
            class="input-field"
            placeholder={`Enter ${field.label.toLowerCase()}`}
          />
        </div>
      {/if}
    {:else}
      <div class=" text-primary text-lg">
        <span>{value ?? ''}</span>
      </div>
    {/if}
  </div>
</div>

<style>
  .field-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.375rem;
    margin-bottom: 1rem;
  }

  .field-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-text-secondary, #64748b);
    transition: color 0.2s;
  }

  .label-content {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }

  .label-icon {
    color: var(--color-text-secondary, #64748b);
    font-size: 1rem;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
  }

  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--color-background, white);
    border: 1px solid var(--color-border, #e2e8f0);
    border-radius: 0.5rem;
    transition: all 0.2s ease;
  }

  .input-group:hover {
    border-color: var(--color-border-hover, #94a3b8);
  }

  .input-group:focus-within {
    border-color: var(--color-primary, #3b82f6);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }

  .input-field {
    width: 100%;
    padding: 0.625rem 0.75rem;
    border: none;
    background: transparent;
    color: var(--color-text-primary, #1e293b);
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  .input-field:focus {
    outline: none;
  }

  .date-input {
    cursor: pointer;
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0;
  }

  .custom-checkbox {
    transform: scale(1.1);
  }

  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .input-group {
      background: var(--color-background-dark, #1e293b);
      border-color: var(--color-border-dark, #334155);
    }

    .input-group:hover {
      border-color: var(--color-border-hover-dark, #475569);
    }

    .input-field {
      color: var(--color-text-primary-dark, #f1f5f9);
    }

    .field-label {
      color: var(--color-text-secondary-dark, #94a3b8);
    }

    .label-icon {
      color: var(--color-text-secondary-dark, #94a3b8);
    }
  }
</style>
