import { writable } from 'svelte/store';
import { toast } from 'svelte-sonner';
import { supabase } from '$lib/api/supabase';
import { appStore } from '$lib/stores/app-store';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';
import { handlePipelineChanges } from './pipeline-realtime';

export enum ConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Error = 'error',
}

export interface ConnectionConfig {
  MAX_RECONNECT_ATTEMPTS: number;
  BASE_RECONNECT_DELAY: number;
  EXCEPTION_TABLES: string[];
}

export class ConnectionService {
  private config: ConnectionConfig;
  private subscription: any = null;
  private reconnectAttempts = 0;
  private reconnectTimeout: NodeJS.Timeout | null = null;

  constructor(config: ConnectionConfig) {
    this.config = config;
  }

  private getBackoffDelay(): number {
    return Math.min(
      this.config.BASE_RECONNECT_DELAY * Math.pow(2, this.reconnectAttempts),
      30000,
    );
  }

  private resetConnectionState() {
    this.reconnectAttempts = 0;
    appStore.setConnectionStatus(ConnectionStatus.Connected);
  }

  private handleDatabaseChanges(payload: any) {
    if (this.config.EXCEPTION_TABLES.includes(payload.table)) {
      return;
    }

    this.invalidateQueries(payload);
    this.handleSpecificTableChanges(payload);
  }

  private invalidateQueries(payload: any) {
    const queryClient = get(appStore).queryClient;
    if (queryClient) {
      queryClient.invalidateQueries([payload.table], {
        exact: false,
        refetchActive: true,
      });
    }
  }

  private handleSpecificTableChanges(payload: any) {
    const { eventType, old, new: newData } = payload;

    // Handle CRM object changes
    if (eventType === 'DELETE' && old?.id) {
      this.handleDeletedItem(old);
    }

    if (newData?.id) {
      this.handleUpdatedItem(newData);
    }

    // Handle pipeline changes
    if (
      ['pipelines', 'pipelinePhases', 'pipelineItems'].includes(payload.table)
    ) {
      handlePipelineChanges(payload);
    }
  }

  private handleDeletedItem(item: any) {
    const currentSelected = get(appStore).selected;
    const currentCrmAccount = get(appStore).crmAccount;
    const currentCrmContact = get(appStore).crmContact;

    if (currentSelected?.id === item.id) {
      appStore.openPopup(false);
      appStore.select(undefined);
    }

    if (currentCrmAccount?.id === item.id) {
      appStore.setCrmAccount(undefined);
    }

    if (currentCrmContact?.id === item.id) {
      appStore.setCrmContact(undefined);
    }
  }

  private handleUpdatedItem(item: any) {
    const currentCrmAccount = get(appStore).crmAccount;
    const currentCrmContact = get(appStore).crmContact;

    if (currentCrmAccount?.id === item.id) {
      appStore.setCrmAccount(item);
    }

    if (currentCrmContact?.id === item.id) {
      appStore.setCrmContact(item);
    }
  }

  private attemptReconnect() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }

    if (this.reconnectAttempts >= this.config.MAX_RECONNECT_ATTEMPTS) {
      console.log('Max reconnection attempts reached');
      toast.error('Connection lost. Please refresh the page.');
      appStore.setConnectionStatus(ConnectionStatus.Error);
      return;
    }

    const token = get(authStore).token;
    if (!token) {
      console.error('No auth token available for reconnection');
      return;
    }

    this.reconnectAttempts++;
    const delay = this.getBackoffDelay();

    console.log(
      `Attempting to reconnect (${this.reconnectAttempts}/${this.config.MAX_RECONNECT_ATTEMPTS}) in ${delay / 1000}s...`,
    );

    this.reconnectTimeout = setTimeout(() => {
      this.unsubscribe();
      setTimeout(() => this.subscribeToChannels(), 100);
    }, delay);
  }

  unsubscribe() {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }

      setTimeout(() => {
        supabase(get(authStore).token).removeAllChannels();
        appStore.setConnectionStatus(ConnectionStatus.Disconnected);
      }, 100);
    } catch (error) {
      console.error('Error during unsubscribe:', error);
    }
  }

  subscribeToChannels() {
    const token = get(authStore)?.token;

    console.log('Subscribing to channels...');

    try {
      this.subscription = supabase(token)
        .channel('*')
        .on('postgres_changes', { event: '*', schema: 'public' }, payload => {
          this.resetConnectionState();
          this.handleDatabaseChanges(payload);
        })
        .on('presence', { event: 'sync' }, () => this.resetConnectionState())
        .on('presence', { event: 'join' }, () => this.resetConnectionState())
        .on('presence', { event: 'leave' }, () => {
          appStore.setConnectionStatus(ConnectionStatus.Disconnected);
          this.attemptReconnect();
        })
        .on('system', { event: '*' }, payload => {
          if (payload.event === 'disconnected') {
            appStore.setConnectionStatus(ConnectionStatus.Disconnected);
            toast.error('Lost connection to server');
            this.attemptReconnect();
          }
        })
        .subscribe(status => {
          switch (status) {
            case 'SUBSCRIBED':
              this.resetConnectionState();
              break;
            case 'CLOSED':
              appStore.setConnectionStatus(ConnectionStatus.Disconnected);
              this.attemptReconnect();
              break;
            case 'CHANNEL_ERROR':
              appStore.setConnectionStatus(ConnectionStatus.Error);
              this.attemptReconnect();
              break;
          }
        });
    } catch (error) {
      console.error('Error during subscription:', error);
      this.attemptReconnect();
    }
  }

  cleanup() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.unsubscribe();
  }
}

// Configuration for the connection service
export const connectionConfig: ConnectionConfig = {
  MAX_RECONNECT_ATTEMPTS: 5,
  BASE_RECONNECT_DELAY: 5000,
  EXCEPTION_TABLES: ['search', 'rates', 'shipments'],
};
