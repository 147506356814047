import { authStore } from '$lib/stores/auth-store';

export const FINANCE_ROLES = ['org:admin', 'org:finance'] as const;
export const SALES_ROLES = ['org:sales'] as const;

export function guardFinanceAccess() {
  return authStore.hasAnyRole(FINANCE_ROLES);
}
export function guardSalesAccess() {
  return authStore.hasAnyRole(SALES_ROLES);
}
