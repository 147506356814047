<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import * as Popover from '$lib/components/ui/popover';
  import { Label } from '$lib/components/ui/label';
  import Icon from '@iconify/svelte';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import { addPipelineItem } from '$lib/api/pipeline-api';

  import { toast } from 'svelte-sonner';

  export let module: string;
  export let item;

  let selectedPipeline = null;
  let selectedPhase = null;
  let showPipelinePopover = false;

  $: availablePipelines = $pipelineStore.filter(p => p.module === module);
  $: availablePhases =
    $pipelineStore
      .find(p => p.id === selectedPipeline)
      ?.phases?.map(p => ({
        name: p.name,
        id: p.id,
      })) || [];

  function handleAddToPipeline() {
    if (selectedPipeline && selectedPhase) {
      addPipelineItem(selectedPhase, module, { item });
      showPipelinePopover = false;
      toast.success('Added to pipeline successfully');
    }
  }
</script>

{#if availablePipelines.length > 0}
  <Popover.Root bind:open={showPipelinePopover}>
    <Popover.Trigger asChild let:builder>
      <Button builders={[builder]} variant="outline" size="sm">
        <Icon icon="mdi:plus" class="mr-1 size-3" />
        Add to Pipeline
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-80">
      <div class="grid gap-4 p-4">
        <div class="space-y-2">
          <p class="text-sm text-muted-foreground">
            Select a pipeline and phase to add this item to
          </p>
        </div>
        <div class="grid gap-2">
          <Label>Pipeline</Label>
          <HantaSelect
            bind:value={selectedPipeline}
            options={availablePipelines.map(p => ({
              value: p.id,
              label: p.name,
            }))}
          />
        </div>
        <div class="grid gap-2">
          <Label>Phase</Label>
          <HantaSelect
            bind:value={selectedPhase}
            options={availablePhases.map(p => ({
              value: p.id,
              label: p.name,
            }))}
            readOnly={!selectedPipeline}
          />
        </div>
        <Button
          variant="default"
          on:click={handleAddToPipeline}
          disabled={!selectedPipeline || !selectedPhase}
        >
          Add to Pipeline
        </Button>
      </div>
    </Popover.Content>
  </Popover.Root>
{/if}
