<script>
  import HantaDateRange from '$lib/components/hanta/date/hanta-date-range.svelte';
  import { today, getLocalTimeZone } from '@internationalized/date';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Button } from '$lib/components/ui/button';
  import { getItems } from '$lib/api/queries';
  import { createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import TimelogsTotal from './timelogs-total.svelte';

  const dateRange = writable({
    start: today(getLocalTimeZone()).subtract({ years: 2 }),
    end: today(getLocalTimeZone()),
  });

  let showDialog = false;

  const reportQuery = createQuery(
    derived(dateRange, $range => ({
      enabled: showDialog,
      queryKey: ['timelogs-report', $range.start, $range.end],
      queryFn: async ({ signal }) => {
        const response = await getItems({
          collection: 'timelogs',
          from: 0,
          to: 1000,
          filters: [
            {
              field: 'date',
              operator: 'gte',
              value: $range.start
                .toDate()
                .toLocaleDateString('sv')
                .substring(0, 10),
              active: true,
            },
            {
              field: 'date',
              operator: 'lte',
              value: $range.end
                .toDate()
                .toLocaleDateString('sv')
                .substring(0, 10),
              active: true,
            },
          ],
          sort: {
            column: 'date',
            order: 'asc',
          },
        });

        const timelogs = response.data || [];
        const groupedData = timelogs.reduce((acc, log) => {
          console.log(log);
          const project = log.project || 'No Project';
          if (!acc[project]) {
            acc[project] = { totalDuration: 0, logs: [] };
          }
          const [hours, minutes] = (log.duration || '0:0')
            .split(':')
            .map(Number);
          const durationInMinutes = hours * 60 + minutes;

          acc[project].totalDuration += durationInMinutes;
          acc[project].logs.push(log);
          return acc;
        }, {});

        return Object.entries(groupedData)
          .sort(([, a], [, b]) => b.totalDuration - a.totalDuration)
          .map(([project, data]) => ({
            project,
            totalDuration: data.totalDuration,
            formattedDuration: formatDuration(data.totalDuration),
            logs: data.logs,
          }));
      },
    })),
  );

  function formatDuration(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  }

  function generateReport() {
    showDialog = true;
  }
</script>

<div class="flex gap-2 items-center">
  <Button variant="outline" on:click={generateReport}>Generate Report</Button>
</div>

<Dialog.Root bind:open={showDialog}>
  <Dialog.Portal>
    <Dialog.Overlay class="fixed inset-0 bg-black/50" />
    <Dialog.Content
      class="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-[625px] bg-card rounded-lg p-6 shadow-lg"
    >
      <Dialog.Header>
        <Dialog.Title>Time Report</Dialog.Title>
        <Dialog.Description>
          <div class="my-4">
            <HantaDateRange bind:value={$dateRange} />
          </div>
        </Dialog.Description>
      </Dialog.Header>

      <div class="py-4 overflow-y-scroll max-h-[50svh]">
        {#if $reportQuery.isLoading}
          <div class="text-center">Loading report data...</div>
        {:else if $reportQuery.isError}
          <div class="text-destructive">
            Error loading report: {$reportQuery.error.message}
          </div>
        {:else if $reportQuery.data}
          <div class="space-y-4">
            {#each $reportQuery.data as { project, formattedDuration, logs }}
              <div class="flex justify-between items-center border-b pb-2">
                <div class="flex flex-col">
                  <span class="font-medium">{project}</span>
                  <span class="font-mono text-xl">{formattedDuration}</span>
                </div>
                <TimelogsTotal
                  {project}
                  data={logs}
                  from={$dateRange.start}
                  to={$dateRange.end}
                />
              </div>
            {/each}
          </div>
        {/if}
      </div>

      <Dialog.Footer>
        <Button variant="outline" on:click={() => (showDialog = false)}
          >Close</Button
        >
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
