import { get } from 'svelte/store';
import { supabase } from './supabase';

import { authStore } from '$lib/stores/auth-store';
import type { Pipeline } from '$lib/types/pipeline';
import {
  pipelineInsertSchema,
  pipelinePhaseInsertSchema,
  pipelineItemInsertSchema,
} from '$db/schema';

const STANDARD_SELECT = ``;

function getIdFieldByModule(module: string): string | null {
  const singularModule = module.endsWith('s') ? module.slice(0, -1) : module;
  return `${singularModule}Id`;
}

export async function deletePipeline(id: string) {
  const { error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .delete()
    .eq('id', id);
}

export async function removePipelineItem(id: string) {
  const { error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .delete()
    .eq('id', id);
}

export async function savePipeline(pipeline: Pipeline) {
  console.log('savePipeline', pipeline);
  const pipelineToSave = pipelineInsertSchema.parse(pipeline);

  // Start a transaction to ensure data consistency
  const { data, error: pipelineError } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .upsert(pipelineToSave)
    .select();

  if (pipelineError) {
    console.debug('error', pipelineError);
    throw new Error(pipelineError.message);
  }

  const savedPipeline = await getPipeline(data?.[0].id);

  // Fetch existing phases with their items
  const { data: existingPhases, error: phasesError } = await supabase(
    get(authStore)?.token,
  )
    .from('pipelinePhases')
    .select('id, items:pipelineItems(id, data)')
    .eq('pipelineId', savedPipeline.id);

  if (phasesError) {
    console.debug('error fetching phases', phasesError);
    throw new Error(phasesError.message);
  }

  const existingPhaseIds = new Set<string>(existingPhases?.map(p => p.id));
  const existingItemIds = new Set<string>(
    existingPhases?.flatMap(p => p.items?.map(i => i.id) || []),
  );

  // Prepare phases and items to upsert and ids to delete
  const phasesToUpsert = [];
  const phaseIdsToDelete = [];
  const itemsToUpsert = [];
  const itemIdsToDelete = Array.from(existingItemIds);

  pipeline.phases.forEach((phase, index) => {
    const phaseToUpsert = pipelinePhaseInsertSchema.parse({
      ...phase,
      phaseIndex: index,
      pipelineId: savedPipeline.id,
      orgId: savedPipeline.orgId,
      userId: savedPipeline.userId,
      createdAt: phase.createdAt || savedPipeline.createdAt,
      modifiedAt: new Date().toISOString(),
      endPhase: phase.endPhase || false,
    });

    phasesToUpsert.push(phaseToUpsert);
    existingPhaseIds.delete(phase.id);

    // Handle items in this phase
    phase.items?.forEach(item => {
      const itemToUpsert = pipelineItemInsertSchema.parse({
        ...item,
        phaseId: phase.id,
        orgId: savedPipeline.orgId,
        userId: savedPipeline.userId,
        data: item.data || {}, // Ensure data field is included
        createdAt: item.createdAt || new Date().toISOString(),
        modifiedAt: new Date().toISOString(),
      });

      itemsToUpsert.push(itemToUpsert);
      const itemIndex = itemIdsToDelete.indexOf(item.id);
      if (itemIndex > -1) {
        itemIdsToDelete.splice(itemIndex, 1);
      }
    });
  });

  phaseIdsToDelete.push(...existingPhaseIds);

  try {
    // Delete removed phases (this will cascade delete their items)
    if (phaseIdsToDelete.length > 0) {
      const { error: deletePhaseError } = await supabase(get(authStore)?.token)
        .from('pipelinePhases')
        .delete()
        .in('id', phaseIdsToDelete);

      if (deletePhaseError) throw deletePhaseError;
    }

    // Delete removed items that weren't deleted by phase cascade
    if (itemIdsToDelete.length > 0) {
      const { error: deleteItemError } = await supabase(get(authStore)?.token)
        .from('pipelineItems')
        .delete()
        .in('id', itemIdsToDelete);

      if (deleteItemError) throw deleteItemError;
    }

    // Upsert new and updated phases
    if (phasesToUpsert.length > 0) {
      const { error: upsertPhaseError } = await supabase(get(authStore)?.token)
        .from('pipelinePhases')
        .upsert(phasesToUpsert);

      if (upsertPhaseError) throw upsertPhaseError;
    }

    // Upsert new and updated items
    if (itemsToUpsert.length > 0) {
      const { error: upsertItemError } = await supabase(get(authStore)?.token)
        .from('pipelineItems')
        .upsert(itemsToUpsert);

      if (upsertItemError) throw upsertItemError;
    }

    return await getPipeline(savedPipeline.id);
  } catch (error) {
    console.error('Error saving pipeline:', error);
    throw error;
  }
}

export async function getPipeline(id: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .select(
      '*, phases:pipelinePhases(*, items:pipelineItems(*, account:accounts(*), contact:contacts(*), candidate:contacts(*)))',
    )
    .eq('id', id);
  return data?.[0];
}

export async function getPipelines() {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .select(
      '*, phases:pipelinePhases(*, items:pipelineItems(*, account:accounts(*), contact:contacts(*), candidate:contacts(*)))',
    )
    .eq('deleted', false)
    .order('createdAt', { ascending: false });
  return data;
}

export async function addPipelineItem(
  phaseId: string,
  module: string,
  { item: newItem }: any,
) {
  const field = getIdFieldByModule(module);

  // Get the name and other relevant data from the item
  const pipelineItem = {
    phaseId,
    name:
      newItem?.name ||
      newItem.contact?.name ||
      newItem.candidateObj?.name,
    customFields: newItem.customFields || {},
    [field]: newItem?.id,
  };

  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .insert(pipelineItem)
    .select(
      `*, account:accounts(*), contact:contacts(*), candidate:contacts(*),
       deal:deals(*), invoice:invoices(*), lead:leads(*), order:orders(*),        
       issue:issues(*), contract:contacts(*), tariff:tariffs(*)`,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data?.[0];
}

export async function savePipelineItem(item: any) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .update(item)
    .eq('id', item.id)
    .select(
      `*, account:accounts(*), contact:contacts(*), candidate:contacts(*),
       deal:deals(*), invoice:invoices(*), lead:leads(*), order:orders(*),        
       issue:issues(*), contract:contacts(*), tariff:tariffs(*)`,
    );

  if (error) {
    throw new Error(error.message);
  }
}

export async function savePipelineItems(phaseId: string, items: any[]) {
  const itemsToSave = items
    .map(item => pipelineItemInsertSchema.parse(item))
    .map(item => ({
      ...item,
      phaseId,
      modifiedAt: new Date().toISOString(),
    }));
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .upsert(itemsToSave)
    .select();

  if (error) {
    console.error('Error saving pipeline items:', error);
    throw new Error(error.message);
  }

  return data;
}

export async function createPipelineItem({
  pipelineId,
  phaseId,
  moduleId,
  module,
  name,
  customFields = {},
}: {
  pipelineId: string;
  phaseId: string;
  moduleId: string;
  module: string;
  name: string;
  customFields?: Record<string, any>;
}) {
  const item = pipelineItemInsertSchema.parse({
    pipelineId,
    phaseId,
    [getIdFieldByModule(module)]: moduleId,
    name,
    customFields,
  });

  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .insert(item)
    .select()
    .single();

  if (error) {
    console.error('Error creating pipeline item:', error);
    throw new Error(error.message);
  }

  return data;
}
