import { writable, get } from 'svelte/store';

type Package = string;

const packages = ['headhunter', 'logistics', 'timelogs', 'crm', 'freelance'];

export type AuthStoreType = {
  packages?: string[];
  user?: any;
  token?: string;
  organization?: any;
  organizations?: any[];
  roles?: string[];
};

function createAuthStore() {
  const store = writable<AuthStoreType>({
    user: undefined,
    token: undefined,
    organization: undefined,
    organizations: undefined,
    roles: undefined,
  });

  const { subscribe, set, update } = store;

  function getName(): string {
    const $store = get(store);
    console.log(JSON.parse(JSON.stringify($store)));
    return $store?.user?.fullName || '-';
  }
  function getPackages(): Package[] {
    const $store = get(store);
    return $store?.packages || ['crm', 'logistics'];
  }

  function hasRole(role: string): boolean {
    const $store = get(store);
    return (
      $store?.user?.organizationMemberships?.find(
        membership => membership.organization.id === $store.organization?.id,
      )?.role === role
    );
  }

  function hasAnyRole(roles: string[]): boolean {
    return roles.some(role => hasRole(role));
  }

  function hasPackage(packageName: Package): boolean {
    const packages = getPackages();
    return packages.includes(packageName);
  }

  function isHeadhunter(): boolean {
    return hasPackage('headhunter');
  }

  function isLogistics(): boolean {
    return hasPackage('logistics');
  }

  function isTimelogs(): boolean {
    return hasPackage('timelogs');
  }

  function isCRM(): boolean {
    return hasPackage('crm');
  }

  function isFreelance(): boolean {
    return hasPackage('freelance');
  }

  return {
    subscribe,
    set,
    update,
    getPackages,
    hasPackage,
    hasRole,
    hasAnyRole,
    isHeadhunter,
    isLogistics,
    isTimelogs,
    isCRM,
    isFreelance,
    getName,
  };
}

export const authStore = createAuthStore();

// Usage example:
// const packages = authStore.getPackages();
