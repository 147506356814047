<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { createSupabaseQuery } from '$lib/api/supabase-realtime/query-store';
  import { createMutations } from '$lib/api/supabase-realtime/mutation';
  import { createUrlState } from '$lib/api/supabase-realtime/url-state';
  import {
    exportData,
    importData,
  } from '$lib/api/supabase-realtime/export-data';
  import {
    createSearchFilter,
    createPriorityFilter,
    combineFilters,
  } from '$lib/api/supabase-realtime/filter-utils';

  import { browser } from '$app/environment';

  import type { Activity } from '$db/schema';

  // Состояние
  let newTodoTitle = '';
  let searchTerm = '';
  let selectedItems = new Set<number>();
  let fileInput: HTMLInputElement;

  // URL-состояние для фильтров и сортировки
  const { filters, sort } = createUrlState();

  // Создаем запрос и мутации
  const { query: activities, destroy: destroyQuery } =
    createSupabaseQuery<Activity>({
      table: 'activities',
      select: '*',
      queryKey: ['activities'],
      infiniteScroll: true,
      sort: {
        field: 'createdAt',
        ascending: false,
      },
      pagination: { pageSize: 100, pageIndex: 0 },
    });

  const { addMutation, updateMutation, bulkUpdateMutation } =
    createMutations<Activity>('activities', ['activities']);

  // Intersection Observer для бесконечной прокрутки
  let loadMoreTrigger: HTMLElement;

  onMount(() => {
    if (browser) {
      const observer = new IntersectionObserver(
        entries => {
          const first = entries[0];
          if (first.isIntersecting) {
            if ($activities.hasNextPage && !$activities.isFetchingNextPage) {
              $activities.fetchNextPage();
            }
          }
        },
        { threshold: 0.5 },
      );

      if (loadMoreTrigger) {
        observer.observe(loadMoreTrigger);
      }

      return () => observer.disconnect();
    }
  });

  // Обработчики действий
  async function handleAddTodo() {
    if (!newTodoTitle.trim()) return;

    try {
      $addMutation.mutate({
        name: newTodoTitle,
        dueDate: new Date().toISOString(),
      });
      newTodoTitle = '';
    } catch (error) {
      console.error('Failed to add todo:', error);
    }
  }

  async function handleToggleTodo(activity: Activity) {
    try {
      $updateMutation.mutate({
        id: activity.id,
        done: !activity.done,
      });
    } catch (error) {
      console.error('Failed to update todo:', error);
    }
  }

  function handleSearch(term: string) {
    const searchFilter = createSearchFilter(term);
    // const priorityFilter = createPriorityFilter(priorityValue);
    $filters = combineFilters([searchFilter]);
  }

  // Групповые операции
  function toggleSelected(id: number) {
    if (selectedItems.has(id)) {
      selectedItems.delete(id);
    } else {
      selectedItems.add(id);
    }
    selectedItems = selectedItems;
  }

  async function handleBulkComplete() {
    if (selectedItems.size === 0) return;

    try {
      $bulkUpdateMutation.mutate({
        ids: Array.from(selectedItems),
        updates: { completed: true },
      });
      selectedItems.clear();
      selectedItems = selectedItems;
    } catch (error) {
      console.error('Failed to update items:', error);
    }
  }

  // Импорт/Экспорт
  async function handleExport() {
    try {
      await exportData('activities', '*');
    } catch (error) {
      console.error('Failed to export:', error);
    }
  }

  async function handleImport(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (!file) return;

    try {
      await importData('activities', file);
      input.value = '';
    } catch (error) {
      console.error('Failed to import:', error);
    }
  }

  onDestroy(destroyQuery);
</script>

<div class="container mx-auto p-4">
  <!-- Панель инструментов -->
  <div class="mb-6 space-y-4">
    <!-- Поиск и фильтры -->
    <div class="flex gap-4">
      <input
        type="text"
        placeholder="Поиск..."
        bind:value={searchTerm}
        on:input={() => handleSearch(searchTerm)}
        class="flex-1 px-4 py-2 border rounded-lg"
      />

      <!--
      <select
        bind:value={priorityValue}
        on:change={() => handleSearch(searchTerm)}
        class="px-4 py-2 border rounded-lg"
      >
        <option value={null}>Все приоритеты</option>
        <option value={1}>Низкий</option>
        <option value={2}>Средний</option>
        <option value={3}>Высокий</option>
      </select>
      -->
    </div>

    <!-- Импорт/Экспорт -->
    <div class="flex gap-4">
      <button
        on:click={handleExport}
        class="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg"
      >
        Экспорт
      </button>

      <input
        type="file"
        accept=".json"
        on:change={handleImport}
        class="hidden"
        bind:this={fileInput}
      />
      <button
        on:click={() => fileInput.click()}
        class="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg"
      >
        Импорт
      </button>
    </div>

    <!-- Добавление новой задачи -->
    <form on:submit|preventDefault={handleAddTodo} class="flex gap-4">
      <input
        type="text"
        placeholder="Новая задача..."
        bind:value={newTodoTitle}
        class="flex-1 px-4 py-2 border rounded-lg"
      />
      <button
        type="submit"
        class="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        disabled={addMutation.isLoading}
      >
        {addMutation.isLoading ? 'Добавление...' : 'Добавить'}
      </button>
    </form>
  </div>

  <!-- Список задач -->
  {#if $activities.isLoading && !$activities.isFetchingNextPage}
    <div class="text-center py-8">Загрузка...</div>
  {:else if $activities.error}
    <div class="text-red-500 py-8">
      Ошибка: {$activities.error.message}
    </div>
  {:else}
    <div class="space-y-2">
      {#each $activities.data.pages.flatMap(page => page.data) as activity (activity.id)}
        <div
          class="flex items-center gap-4 p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow"
        >
          <input
            type="checkbox"
            checked={selectedItems.has(activity.id)}
            on:change={() => toggleSelected(activity.id)}
            class="w-5 h-5"
          />

          <input
            type="checkbox"
            checked={activity.done}
            on:change={() => handleToggleTodo(activity)}
            class="w-5 h-5"
          />

          <span class:line-through={activity.done} class="flex-1">
            {activity.name ?? activity.description ?? '-'}
          </span>

          <span class="text-sm text-gray-500">
            {new Date(
              activity.dueDate ?? activity.startDate,
            ).toLocaleDateString()}
          </span>

          <div class="flex items-center gap-2">
            <span
              class={`px-2 py-1 rounded-full text-sm ${
                activity.priority === 1
                  ? 'bg-blue-100 text-blue-800'
                  : activity.priority === 2
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-red-100 text-red-800'
              }`}
            >
              {activity.priority === 1
                ? 'Низкий'
                : activity.priority === 2
                  ? 'Средний'
                  : 'Высокий'}
            </span>
          </div>
        </div>
      {/each}

      <!-- Триггер для бесконечной прокрутки -->
      <div bind:this={loadMoreTrigger} class="h-4" />
    </div>
  {/if}

  <!-- Панель групповых операций -->
  {#if selectedItems.size > 0}
    <div
      class="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg flex gap-4"
    >
      <span class="text-sm text-gray-500">
        Выбрано: {selectedItems.size}
      </span>

      <button
        on:click={handleBulkComplete}
        class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
      >
        Завершить все
      </button>
    </div>
  {/if}
</div>
